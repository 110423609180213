/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback} from 'react';
import './iCn3D.css';
import getcolor from './getcolor';
import evalTManno from './evalTManno';
import Icn3dViewer from './Icn3dViewer';
  
function ICn3D({ genename, style, study, enzyme, uniprotID,
  tm_manual,
  tm_uniprot,
  tm_deeptmhmm,
  tm_tmbed}) {
  const [loading, setLoading] = useState(true);
  const [urling, setUrling] = useState(true);
  const [isdata, setIsdata] = useState(false);
  const [sites, setSites] = useState([]);
  const [url, setUrl] = useState('');
  const [[commandIndex,moreCommand], setMoreCommand] = useState([0,'']);
  const [selectedTM, setSelectedTM] = useState('none'); // keep track of selected TM source
  const [tmOptions, setTMOptions] = useState([]);
  const source = axios.CancelToken.source();

  const baseUrl = 'https://www.ncbi.nlm.nih.gov/Structure/icn3d/';

  const siteFeatureForStudy = {'mitoatlas':'Localization_mitoatlas'
      ,'sarscov2':'sarscov2_interactome'}

  const [buttonText, setButtonText] = useState('Copy URL to clipboard');

  useEffect(()=>{
    if(urling) return;
    let options = [];
    if (tm_manual.length>0) {
      const scores = evalTManno({sites:sites,TMs:tm_manual});
      options.push({ value: "manual", 
          label: `TM from manual annotation, score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
          score1: scores.score1, 
          score2: scores.score2, 
          maxscore: Math.max(scores.score1, scores.score2), 
          src: generateUrlCommand(tm_manual)});
      //setSelectedTM('uniprot'); // set selectedTM to 'uniprot' if parsedTMuniprot is not empty
    }
    if (tm_uniprot.length>0) {
        const scores = evalTManno({sites:sites,TMs:tm_uniprot});
        options.push({ value: "uniprot", 
            label: `TM from UniProt, score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
            score1: scores.score1, 
            score2: scores.score2, 
            maxscore: Math.max(scores.score1, scores.score2), 
            src: generateUrlCommand(tm_uniprot)});
        //setSelectedTM('uniprot'); // set selectedTM to 'uniprot' if parsedTMuniprot is not empty
    }
    if (tm_deeptmhmm.length>0) {
        const scores = evalTManno({sites:sites,TMs:tm_deeptmhmm});
        options.push({ value: "deeptmhmm",
            label: `TM from DeepTMHMM, score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
            score1: scores.score1, 
            score2: scores.score2,
            maxscore: Math.max(scores.score1, scores.score2),
            src: generateUrlCommand(tm_deeptmhmm)});
    }
    if (tm_tmbed.length>0) {
        const scores = evalTManno({sites:sites,TMs:tm_tmbed});
        options.push({ value: "tmbed", 
            label: `TM from TMbed, score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
            score1: scores.score1, 
            score2: scores.score2,
            maxscore: Math.max(scores.score1, scores.score2),
            src: generateUrlCommand(tm_tmbed)});
    }

      options.push({value: 'none',
      label: 'no TM domain annotation',
      maxscore: -0.01,
      src: generateUrlCommand([]) });

    //draw the topology of the highest scoring protein with the desired direction

    if( selectedTM === 'none' ){
      if(options.length > 1 ){
        //sort tm options
        console.log('sorting options');
        options.sort((a,b)=>b.maxscore-a.maxscore);
        console.log(options);
      }
      // set the TM options when the component mounts
      const updatedOptions = options.slice(); // create a copy of the options array

      setTMOptions(updatedOptions);
      console.log('options');
      console.log(updatedOptions);
      
      setSelectedTM(options[0].value);
    
    } 

  },[ genename, sites, tm_deeptmhmm, tm_tmbed, tm_uniprot,urling ]);

  const handleCopyUrl = useCallback(() => {
    const isCopied = navigator.clipboard.writeText(tmOptions.find(option => option.value === selectedTM)?.src ?? tmOptions[0].src);
    if (isCopied) {
      setButtonText('URL copied!');
    } else {
      alert('Copying URL to clipboard is not supported in this browser.');
    }
  }, [url,selectedTM]);

  useEffect(() => {
    if (buttonText !== 'Copy URL to clipboard') {
      const timeoutId = setTimeout(() => {
        setButtonText('Copy URL to clipboard');
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [buttonText]);

  useEffect(() => {
    setLoading(true);

    if ( study === 'mitoatlas') {
      const queries = [
        axios.get(`https://spatiomics.org/api/pldata?study=${study}&uid=${uniprotID}&sitefeature=Localization_mitoatlas&enzyme=${'APEX2'.toLowerCase()}`),
        axios.get(`https://spatiomics.org/api/pldata?study=${study}&uid=${uniprotID}&sitefeature=Localization_mitoatlas&enzyme=${'BioID'.toLowerCase()}`)
      ];
      Promise.all(queries)
        .then(([response, response2]) => {
          console.log(`got ${study} data of ${uniprotID}`);

          const combinedArray = [...response.data.map(obj => ({ ...obj, 

            enzyme: 'APEX2', 
            
            site: parseInt(obj.site_id.split('_')[1]) })), 

            ...response2.data.map(obj => ({ ...obj, 
              
            enzyme: 'BioID',

            site: parseInt(obj.site_id.split('_')[1]) })),

            ].sort((a, b) => a.site - b.site);

          setSites(combinedArray);

          if (combinedArray.length > 0) {

            setIsdata(true);

          }

        })
        .catch(error => {
          console.error(`there was an error communicating to the API: ${error}`);
        })
        .finally(() => {
          setLoading(false);
        });
      return () => {
        source.cancel();
      };
    } else if ( study === 'sarscov2' ) {
    const queries = [
      axios.get(`https://spatiomics.org/api/pldata?study=${study}&uid=${uniprotID}&sitefeature=sarscov2_interactome&enzyme=${enzyme.toLowerCase()}`),
      ];
    Promise.all(queries)
      .then(([response]) => {

      console.log(`got ${study} ${enzyme} data of ${uniprotID}`);

      console.log(response.data);

      const combinedArray = [...response.data.map(obj => ({ ...obj, 

        enzyme: 'TurboID', 
        
        site: parseInt(obj.site_id.split('_')[1]) })), ]

      setSites(combinedArray);

      console.log(combinedArray.length);

      if (combinedArray.length > 0) {

      setIsdata(true);
      
      }
      
      })
      .catch(error => {
        console.error(`there was an error communicating to the API: ${error}`);
      })
      .finally(() => {
      setLoading(false);
      });
      return () => {
      source.cancel();
      };
      }
    }, [uniprotID]);

useEffect(() => {
if (loading || !setUrling) {
return;
}
console.log('updating URL command');
let urlCommand = `?afid=${uniprotID}&?afmem=off&command=set mode all;select all;color A9A9A9;`;
urlCommand+='{TMcommand}';
if (sites.length > 0) {
    // modify the urlCommand using each item in the sites array
    //for performance, select multiple items at once
    if  (study === 'mitoatlas'){
      const featureSites={};
      sites.forEach(obj=>{
          if(!Object.keys(featureSites).includes(obj.Localization_mitoatlas)){
            featureSites[obj.Localization_mitoatlas]=[obj.site];
          } else {
            featureSites[obj.Localization_mitoatlas].push(obj.site);
          }
        }
      )
      console.log('uniqueFeatre');
      console.log(featureSites);
      for (let key in featureSites){
        urlCommand+=`select $${uniprotID}.A:${featureSites[key].join(',')}; style sidec stick2;`
        urlCommand += ` color ${getcolor(style,key,'darkhex')};`
      }
    } else if ( study === 'sarscov2' ){
      const featureSites={};
      sites.forEach(obj=>{
          if(!Object.keys(featureSites).includes(obj.sarscov2_interactome)){
            featureSites[obj.sarscov2_interactome]=[obj.site];
          } else {
            featureSites[obj.sarscov2_interactome].push(obj.site);
          }
        }
      )
      console.log('uniqueFeatre');
      console.log(featureSites);
      for (let key in featureSites){
        urlCommand+=`select $${uniprotID}.A:${featureSites[key].join(',')}; style sidec stick2;`
        urlCommand += ` color ${getcolor(style,key,'darkhex')};`
      }

    }
  }
urlCommand+='set mode all;';
const url = `${baseUrl}${urlCommand}`;
setUrl(url);
setUrling(false);
console.log(url);
}, [loading]);

const handleSaveBtnClick = useCallback((event) => {

  const asyncFunction = async () => {

  setMoreCommand([previndex => previndex +1,'set background transparent;export canvas 4;']);  

  await new Promise(resolve => setTimeout(resolve, 1000));

  setMoreCommand([previndex => previndex +1,'set background black']);  

  }

  asyncFunction();

}, []);

//detect selectedTM change and

const handleTMChange = useCallback((event) => {
  setSelectedTM(event.target.value);  
}, [tmOptions]);

//detect selectedTM change and pass command to viewer

useEffect(()=>{
  if(!tmOptions[0]) return;
  setMoreCommand([previndex => previndex +1,tmOptions.find(option => option.value === selectedTM)?.src.split('command=')[1] ?? tmOptions[0].src.split('command=')[1]]);
  
  console.log('changing morecommand');
},[selectedTM])

const generateUrlCommand = (tmData) => {
  let urlCommand = ``;
  if (tmData.length > 0) {
    tmData.forEach((tm, i) => {
      let tmColor = '';
      if (i % 2 == 0) {
        tmColor = getcolor(style, 'tm-odd', 'darkhex');
      } else {
        tmColor = getcolor(style, 'tm-even', 'darkhex');
      }
      urlCommand += `select $${uniprotID}.A:${tm.start}-${tm.end}; color ${tmColor};`;
    });
  }
  urlCommand += 'set mode all;';
  return url.replace('{TMcommand}',urlCommand);
};

if (urling || loading) {
return <div>Loading...</div>;
} else if (!isdata) {
return <div>Sorry! this protein was not detected in the study {study}.</div>;
}
return (
  <div>
  <div className="iframe-header">
    <select
      className="iframe-select"
      value={selectedTM}
      onChange={handleTMChange}
      style={{
        border: '1px solid #ccc',
        borderRadius: '0px',
        padding: '8px 16px',
        fontSize: '20px',
        fontFamily: 'Arial',
        cursor: 'pointer',
      }}
    >
      {tmOptions.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
    <button
      className="iframe-button"
      onClick={handleCopyUrl}
      style={{
        border: '1px solid #ccc',
        borderRadius: '0px',
        padding: '8px 16px',
        fontSize: '20px',
        fontFamily: 'Arial',
        cursor: 'pointer',
      }}
    >
      {buttonText}
    </button>

    <button
      className="iframe-button"
      onClick={handleSaveBtnClick}
      style={{
        border: '1px solid #ccc',
        borderRadius: '0px',
        padding: '8px 16px',
        fontSize: '20px',
        fontFamily: 'Arial',
        cursor: 'pointer',
      }}
    >
      save as high quality .png
    </button>

  </div>
  <Icn3dViewer 
    uniprotID={uniprotID} 
    width={1800}
    initCommand={tmOptions.length > 0 ? tmOptions[0].src.split('command=')[1] : ''}
    moreCommand={moreCommand}
    commandIndex={commandIndex}
/>
</div>
);
}

export default ICn3D;