/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback} from 'react';
import './iCn3D.css';
import MoimIcn3dViewer from './MoimIcn3dViewer';
  
function MoimStructure({ fileName, geneNameA, geneNameB }) {

  const [urling, setUrling] = useState(true);

  const [url, setUrl] = useState('https://www.ncbi.nlm.nih.gov/Structure/icn3d/');
  
  const [[commandIndex,moreCommand], setMoreCommand] = useState([0,'']);

  const baseUrl = 'https://www.ncbi.nlm.nih.gov/Structure/icn3d/';

  const [buttonText, setButtonText] = useState('Copy URL to clipboard');

  const handleCopyUrl = useCallback(() => {

    const isCopied = navigator.clipboard.writeText(url);

    if (isCopied) {

      setButtonText('URL copied!');

    } else {

      alert('Copying URL to clipboard is not supported in this browser.');

    }

  }, [url]);

  useEffect(() => {

    if (buttonText !== 'Copy URL to clipboard') {

      const timeoutId = setTimeout(() => {

        setButtonText('Copy URL to clipboard');

      }, 3000);

      return () => clearTimeout(timeoutId);

    }

  }, [buttonText]);

  useEffect(() => {

    if (!urling) {

      return;

    }

    console.log('updating URL command');

    let urlCommand = `?command = load url https://moim.spatiomics.org/pdb/${fileName}.pdb | type pdb;`;
    //let urlCommand = `?command = load url https://moim.spatiomics.org/pdb/${fileName}.pdb | type pdb; load url https://moim.spatiomics.org/pdb/${fileName}_best_pae.json | type pae;`;

    const url = `${baseUrl}${urlCommand}`;

    setUrl(url);

    setUrling(false);

    console.log(url);

  }, [fileName]);

  const handleSaveBtnClick = useCallback((event) => {

    const asyncFunction = async () => {

    setMoreCommand([previndex => previndex +1,'set background transparent;export canvas 4;']);  

    await new Promise(resolve => setTimeout(resolve, 1000));

    setMoreCommand([previndex => previndex +1,'set background black']);  

    }

    asyncFunction();

  }, []);

  if (urling || !url) {

    return <div>Loading...</div>;

  } else {
    
    return (
      <div>
        <div className="iframe-header">
        <a
            className = "iframe-button"

            href = {`https://moim.spatiomics.org/pdb/${fileName}.pdb`}

            download = {`${geneNameA + '-' + geneNameB}.pdb`}

            style = {{
              display: 'inline-block',
              border: '1px solid #ccc',
              borderRadius: '0px',
              padding: '8px 16px',
              fontSize: '20px',
              fontFamily: 'Arial',
              cursor: 'pointer',
            }}
          >
            download {`${geneNameA + '-' + geneNameB}.pdb`}

          </a>

          <a
            className = "iframe-button"

            href = {`https://moim.spatiomics.org/pdb/${fileName}_best_pae.json`}

            download = {`${geneNameA + '-' + geneNameB}.json`}

            style = {{
              display: 'inline-block',
              border: '1px solid #ccc',
              borderRadius: '0px',
              padding: '8px 16px',
              fontSize: '20px',
              fontFamily: 'Arial',
              cursor: 'pointer',
            }}
          >
            download PAE.json

          </a>

          <button
            className="iframe-button"
            onClick={handleCopyUrl}
            style={{
              border: '1px solid #ccc',
              borderRadius: '0px',
              padding: '8px 16px',
              fontSize: '20px',
              fontFamily: 'Arial',
              cursor: 'pointer',
            }}
          >
            {buttonText}
          </button>
  
          <button
            className="iframe-button"
            onClick={handleSaveBtnClick}
            style={{
              border: '1px solid #ccc',
              borderRadius: '0px',
              padding: '8px 16px',
              fontSize: '20px',
              fontFamily: 'Arial',
              cursor: 'pointer',
            }}
          >
            save as high quality .png
          </button>
  
        </div>
        <MoimIcn3dViewer
          fileName={fileName}
          width={1800}
          initCommand={''}
          moreCommand={moreCommand}
          commandIndex={commandIndex}
      />
    </div>
    );

  }

  }

export default MoimStructure;