import { saveAs } from "file-saver";
import * as d3 from 'd3';
import { toJpeg, toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';


export default function saveButton(containerRef, saveOptions, svgRef, targetwidth, targetheight, filename) {
  const saveSVG = () => {
    const svgData = new XMLSerializer().serializeToString(svgRef.current);
    const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    saveAs(blob, filename + ".svg");
  };
  
  const saveJPEG = () => {
    toJpeg(svgRef.current,{ quality: 1, width:targetwidth,height:targetheight})
    .then(function (blob) {
      saveAs(blob, filename + ".jpg");
    })
    .catch(function (error) {
      console.error('Error saving SVG as JPEG:', error);
    });
  };

  const savePNG = () => {
    toPng(svgRef.current,{ quality: 1, width:targetwidth,height:targetheight})
    .then(function (blob) {
      saveAs(blob, filename + ".png");
    })
    .catch(function (error) {
      console.error('Error saving SVG as PNG:', error);
    });
  };

  const savePDF =() => {
    // Get the SVG element and target dimensions
    const svgNode = svgRef.current;
     // Create a new jsPDF document object
    const doc = new jsPDF({
      unit: 'px',
      format:[targetwidth, targetheight]
    });
    // Convert the SVG graphic to PDF shapes and add it to the document
    toPng(svgRef.current,{ quality: 1, width:targetwidth,height:targetheight})
      .then(function (dataUrl) {
        // Embed the image in the PDF document
        doc.addImage(dataUrl, 'PNG', 0, 0, targetwidth/1.2, targetheight/1.2);
        // Save the PDF document to a file
        doc.save(filename+'.pdf');
      })
      
      .catch(function (error) {

        console.error('Error saving SVG as PNG:', error);

      });
  };

  d3.select(containerRef.current).selectAll('*').remove();

  const saveButton = d3.select(containerRef.current)
    .append("button")
    .text("Save")
    .style("z-index", "1")
    .style("border-radius", "0")
    .style("width", "70px")
    .style("height", "30px")
    .style("font-size", '20px')
    .style("font-family", "Arial")
    .style("text-align", "center");

  saveButton.style("line-height", saveButton.style("height"));

  saveButton.on("click", (event) => {

    let saveMenu = d3.select(containerRef.current).select(".save-menu");

    if (saveMenu.empty()) {
      // Create a new save menu
      saveMenu = d3.select(containerRef.current)
        .append("ul")
        .attr("class", "save-menu")
        .style("position", "absolute")
        .style("list-style", "none")
        .style("padding", "5px")
        .style("background-color", "white")
        .style("border", "1px solid black")
        .style("z-index",2);

      saveOptions.forEach(function(option) {

        const saveItem = saveMenu.append("li")
          .style("cursor", "pointer")
          .text(option.label)
          .on("click", function() {

            switch (option.format) {

              case "svg":
                saveSVG();
                break;

              case "jpeg":
                saveJPEG();
                break;

              case "pdf":
                savePDF();
                break;

              case "png":
                savePNG();
                break;
            }

            saveMenu.remove();

          })
          .on("mouseover", function() {

            d3.select(this)
              .style("background-color", "gray")
              .style("color", "white");

          })
          .on("mouseout", function() {

            d3.select(this)
              .style("background-color", "white")
              .style("color", "black");

          });
      });
    }

    saveMenu.style("top", event.clientY - 20 + "px")
      .style("left", event.clientX + 20 + "px")
      .style("position", "fixed")
      .style("display", "block");
    // Hide the save menu immediately when the user clicks outside of it
    d3.select(document).on("click", function(event) {
        if (!saveMenu.node().contains(event.target) && !saveButton.node().contains(event.target)) {

        saveMenu.style("display", "none");

        }
        
    });
    // Hide the save menu when the viewport is scrolled
    window.onscroll = function() {

      saveMenu.style("display", "none");

    }

    });
    

    return saveButton;
    }
    
