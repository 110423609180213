/* eslint-disable no-restricted-globals */

import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import DynamicTitle from './DynamicTitle';

import SearchBar from './SearchBar';

import axios from 'axios';

import getcolor from './getcolor';

import { debounce } from 'lodash';

import Version from './Version';

import Dev from './Dev';

import SarsCoV2023Paper from './SarsCoV2023Paper';

export default function SarsCoV2Main() {

    const currentHost = window.location.hostname;

    if (currentHost.split('.').length < 3 ) {

      const newHost = 'sarscov2.'+currentHost;

      window.location.hostname = newHost;

    }

    let subDomain = '';

    if (currentHost.split('.').length === 3 ) {

      subDomain = currentHost.split('.')[0];

    }

    const [style, setStyle] = useState([]);

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    const source = axios.CancelToken.source();

    const [hoveredLink, setHoveredLink] = useState(null);

    const [hoveredCard, setHoveredCard] = useState(null);

    const [filter, setFilter] = useState('');

    const handleFilterChange = (event) => {

        setFilter(event.target.value);

    };

    const filteredData = data.filter((protein) =>

        protein.Genename.toLowerCase().includes(filter.toLowerCase()) ||

        protein.UniProt_ID.toLowerCase().includes(filter.toLowerCase()) ||

        protein['Synonym(s)'].toLowerCase().includes(filter.toLowerCase())

    );

    const handleMouseEnter = (index) => {

      setHoveredLink(index);

    };

    const handleMouseLeave = () => {

      setHoveredLink(null);

    };


    const handleCardMouseEnter = (index) => {

      setHoveredCard(index);

    };

    const handleCardMouseLeave = () => {

      setHoveredCard(null);

    };

    const filterBarStyle = {

      display: 'inline-block',

      minWidth: 500,

      border:'solid 1px black',

      fontSize: 20,

      fontFamily: 'Arial',

      margin: 1,

      padding: 2,

      textAlign: 'left',

    }

    const cardStyle = {

      display: 'inline-block',

      minWidth: 600,

      border:'solid 1px black',

      fontSize: 20,

      fontFamily: 'Arial',

      margin: 1,

      padding: 2,

      textAlign: 'left',

    }

    const hoverCardStyle = {

      display: 'inline-block',

      minWidth: 600,

      border:'solid 2px black',

      fontSize: 20,

      fontFamily: 'Arial',

      margin: 1,

      padding: 2,

      textAlign: 'left',

      background: '#f2f2f2',

    }

    const spanStyle = {

      display: 'inline-block',

      margin: 5,

      maxWidth: 500,

      wordWrap: 'break-word', /* Added */

      whiteSpace: 'normal',

    };

    const linkStyle = {

      display: 'inline',

      color: 'black',

      textDecoration: 'none',

    }

    const hoverLinkStyle = {

      display: 'inline',

      color: 'red',

      textDecoration: 'underline',

    };

    useEffect(() => {

        if(subDomain!=='sarscov2') return;

        //set the title of the page to SARSCoV2
        document.title = 'SARS-CoV-2';

        const debouncedQuery = debounce(() => {
        
        setLoading(true);

        const queries=[

          axios.get('https://spatiomics.org/api/style',),

          axios.get('https://spatiomics.org/api/pldata?study=sarscov2&sitefeature=sarscov2_interactome',),

        ];
    
        Promise.all(queries)

              .then(([response,response2]) => {

                setStyle(response.data);
                //sort data by number of specific sites in decreasing order
                setData(response2.data[0].sort((a,b) => b.sites.split(',').filter((element, index, self) => {

                  return self.indexOf(element) === index;

                }).length - a.sites.split(',').filter((element, index, self) => {

                  return self.indexOf(element) === index;

                }).length));

              })

              .catch(error => {

                console.error(`there was an error communicating to the API: ${error}`);

              })

              .finally(() => {

                setLoading(false);

              });

            }, 100);
    
            debouncedQuery();

            return () => {

              source.cancel();

            };

          }, []);

    if(subDomain !== 'sarscov2'){
      return <div>Redirecting to sarscov2.spatiomics.org...</div>
    }

    return <><div className="App">

    <div className="App-header">

      <DynamicTitle />

    </div>

    <SearchBar />

    <div className="title">SARS-CoV-2</div>

    <div className="subtitle">Publication(s)</div>

    <SarsCoV2023Paper />

    <div className="filter-bar"
      >
        <input

            style={filterBarStyle}

            type="text"

            placeholder="Filter by Gene Name or UniProtID"

            value={filter}

            onChange={handleFilterChange}

        />
    </div>

    { !loading && filteredData.length > 0 &&

                filteredData.map((protein,index)=>{
    //sort first before mapping
    const sites = protein.sites.split(',').map((d,i)=>{

      return {site_id:'K'+d.split('_')[1], sarscov2_interactome:protein.sarscov2_interactome.split(',')[i]}

    })

    const deduplicatedSites = sites.reduce((uniqueArray, obj) => {
      // Check if the site_id already exists in uniqueArray
      const exists = uniqueArray.some(item => item.site_id === obj.site_id);
      
      // If the site_id doesn't exist, add the object to uniqueArray
      if (!exists) {

        uniqueArray.push(obj);

      }
      
      return uniqueArray;
      //sort sites by site_id
    }, []).sort((a,b)=> (parseInt(a.site_id.substring(1,)))-parseInt(b.site_id.substring(1,)));

      const url = '/'+protein.UniProt_ID;

      const interactome = [];

      if(protein.sarscov2_interactome.includes('ORF3a')){

        interactome.push('ORF3a');

      }
      
      if(protein.sarscov2_interactome.includes('M')){

        interactome.push('M');
      }

      return <><div className='card'

          key={index}

          style = {index === hoveredCard ? hoverCardStyle : cardStyle }

          onMouseEnter={() => handleCardMouseEnter(index)}

          onMouseLeave={handleCardMouseLeave}>

          <span style={spanStyle}>Genename:&nbsp;

            <Link key={index}

              style = {index === hoveredLink ? hoverLinkStyle : linkStyle }

              onMouseEnter={() => handleMouseEnter(index)}

              onMouseLeave={handleMouseLeave}

              to={url}
            >
          {protein.Genename}

            </Link>

          </span>

          <br></br>

          {protein['Synonym(s)'] &&  <>

          <span style={spanStyle}>Synonym(s): {protein['Synonym(s)']}

          </span>

          <br></br></>}

          <span style={spanStyle}>UniProtID: {protein.UniProt_ID}

          </span>

          <br></br>

          {interactome.length>0 &&

          <>

          <span style={spanStyle}>Interactome: {interactome.map((d,i)=>{

            return <><span style={{background:getcolor(style,'SARS-CoV-2_'+d,'lighthex')}}>{d}</span>{ i + 1 !== interactome.length && ', '}</>;
          
          })}
          </span>

          <br></br>

          </>

          }

          <span style={spanStyle}>Specific site(s): {deduplicatedSites.map((d,i)=>{

            return <>

                <span style={{background:getcolor(style,d.sarscov2_interactome,'lighthex')}}>

                  
                  {d.site_id}

                </span>{i!=deduplicatedSites.length-1 && ', '}
                
              </>;

            })}

          </span>

          <br></br>

        </div>

        <br></br>

        </>

    })}
    { loading && <div>loading...</div> } 
    
    { !loading && data.length < 1 && <div>backend data fetch failed</div> }

    </div>

    <Dev />    

    <Version />

    </>
}