/* eslint-disable no-restricted-globals */
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useState, useEffect, useRef} from 'react';
import './MoimGeneral.css';
import * as d3 from 'd3';
import saveButton from './saveButton';
import MoimPAE from './MoimPAE';

// Function to calculate Pearson correlation coefficient
function pearsonCorrelation(x, y) {
  let n = x.length;
  let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0, sumY2 = 0;
  for (let i = 0; i < n; i++) {
    sumX += x[i];
    sumY += y[i];
    sumXY += (x[i] * y[i]);
    sumX2 += (x[i] * x[i]);
    sumY2 += (y[i] * y[i]);
  }
  let numerator = sumXY - (sumX * sumY / n);
  let denominator = Math.sqrt((sumX2 - sumX * sumX / n) * (sumY2 - sumY * sumY / n));
  return numerator / denominator;
}

// Function to calculate linear regression parameters
function linearRegression(x, y) {
  let n = x.length;
  let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;
  for (let i = 0; i < n; i++) {
    sumX += x[i];
    sumY += y[i];
    sumXY += (x[i] * y[i]);
    sumX2 += (x[i] * x[i]);
  }
  let slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  let intercept = (sumY - slope * sumX) / n;
  return { slope, intercept };
}

function MoimGeneral({ 
  geneNameA, 
  proteinNameA, 
  uniProtIDA,
  geneNameB, 
  proteinNameB, 
  uniProtIDB, 
  pearson,
  maxScore,
  fileName,
  lengthA,
  lengthB,
})  {
  
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const source = axios.CancelToken.source();

  const btncontainerRef=useRef(null);

  const [cellTypeToggleIsOn, setCellTypeToggleIsOn] = useState(true);

    function handleCellTypeToggle() {
        setCellTypeToggleIsOn(!cellTypeToggleIsOn);
    }

  useEffect(() => {

    const debouncedQuery = debounce(() => {

    setLoading(true);

    const queries=[

      axios.get(`https://spatiomics.org/api/ccle?uida=${uniProtIDA}&uidb=${uniProtIDB}`,),

    ];

    Promise.all(queries)
          .then(([response]) => {

            setData(response.data);
            console.log("data to draw ccle scatter plot");
            console.log(response);
          })
          .catch(error => {

            console.error(`there was an error communicating to the API: ${error}`);

          })
          .finally(() => {

            setLoading(false);

          });
        }, 100);

        debouncedQuery();
        return () => {
          source.cancel();
        };
      }, [uniProtIDA, uniProtIDB]);

      const d3Container = useRef(null);

      useEffect(() => {
        if (!loading && d3Container.current) {
          const svg = d3.select(d3Container.current);
          
          // Assuming that data[0] is for uidA and data[1] is for uidB
          const uidAValues = Object.entries(data[0]).slice(1); // skip the 'uid' field
          const uidBValues = Object.entries(data[1]).slice(1); // skip the 'uid' field

          // Map uidBValues into an object for easier lookup
          const uidBMap = new Map(uidBValues);

          // Filter out non-matching keys and null values
          const validDataPoints = uidAValues.filter(([key, value]) => uidBMap.has(key) && value !== null && uidBMap.get(key) !== null);

          // Convert string values to numbers and create points for scatter plot
          const points = validDataPoints.map(([key, value]) => {
            const parts = key.split('_'); // Split the key by underscores
            const cellLine = parts[0]; // The part before the first underscore
            let cellType = '';
          
            if (parts.length > 2) {
              // Join the parts between the first and last underscore for origin
              cellType = parts.slice(1, parts.length - 1).join('_');
            } else if (parts.length === 2) {
              // If there's only one underscore, the origin is the part after it
              cellType = parts[1];
            }
          
            return {
              cellLine: cellLine,
              cellType: cellType,
              x: +value,
              y: +uidBMap.get(key),
            };
          });

          // Define dimensions
          const margin = { top: 20, right: 320, bottom: 70, left: 70 };
          const width = 800 - margin.left - margin.right;
          const height = 500 - margin.top - margin.bottom;

          // Clear SVG before redrawing
          svg.selectAll("*").remove();

          // Create scales
          // Calculate the extent of your data
          const xExtent = d3.extent(points, d => d.x);

          const yExtent = d3.extent(points, d => d.y);

          // Define your desired margin; adjust this value as needed
          const scaleMargin = 1;

          // Adjust the extent to include the margin
          const adjustedXExtent = [xExtent[0] - scaleMargin, xExtent[1] + scaleMargin];

          const adjustedYExtent = [yExtent[0] - scaleMargin, yExtent[1] + scaleMargin];

          // Define the xScale with the adjusted extent
          const xScale = d3.scaleLinear()
              .domain(adjustedXExtent)
              .range([margin.left, width + margin.left]); // Ensure the scale fits within the SVG's width, accounting for right margin

          const yScale = d3.scaleLinear()
              .domain(adjustedYExtent)
              .range([height + margin.top, margin.top]); // Ensure the scale fits within the SVG's width, accounting for right margin
          // Create axes
          const xAxis = d3.axisBottom(xScale);
          const yAxis = d3.axisLeft(yScale);

          // Prepare the color scale for cellType
          let cellTypes = Array.from(new Set(points.map(d => d.cellType)));

          let colorScale = d3.scaleOrdinal()
              .domain(cellTypes) // Assuming cellTypes is an array of your different cell types
              .range(Array(cellTypes.length).fill("#4292c6")); // Fill an array with the same length as cellTypes with the color blue

          // Define shapes
          let symbols = [
            d3.symbolCircle,
            d3.symbolCircle,
            d3.symbolCircle,
            // Add custom paths for more shapes if needed
          ];

          if (cellTypeToggleIsOn){ 

            colorScale = d3.scaleOrdinal(d3.schemeCategory10) // You can choose other color schemes
            .domain(cellTypes);

            // Define shapes
            symbols = [
              d3.symbolCircle,
              d3.symbolSquare,
              d3.symbolTriangle,
              // Add custom paths for more shapes if needed
            ];


          } 
          
          let anyPointClicked = false; // Variable to track if any point is clicked

          // Append the scatter plot points with event listeners
          svg.append('g')
          .selectAll(".data-point")
          .data(points)
          .enter()
          .append("path") // Use path for different shapes
            .attr("class", "data-point") // Add the class here
            .attr("transform", d => `translate(${xScale(d.x)},${yScale(d.y)})`) // Position the shapes
            .attr("d", d3.symbol().type(d => symbols[Math.floor(cellTypes.indexOf(d.cellType) / 10)])) // Set shape based on cellType
            .style("fill", d => colorScale(d.cellType)) // Color based on cellType
            //.style("fill", "#4292c6")
            .on("mouseover", function(event, d) {
              if (!this.clicked && !anyPointClicked) { // Check if the circle was not clicked and no other point is clicked
                d3.select(this)
                  .raise()
                  .attr("stroke", "black") // Color of the boundary
                  .attr("stroke-width", 2); // Width of the boundary
        
                // Tooltip text lines
                const lines = [
                  `Cell Line: ${d.cellLine}`,
                  `Cell Type: ${d.cellType}`,
                  `${geneNameA}: ${d.x}`,
                  `${geneNameB}: ${d.y}`
                ];
        
                // Calculate width and height of the tooltip based on text
                // Calculate width of each line and find the maximum
                let maxWidth = 0;
                lines.forEach(line => {
                  const tempText = svg.append("text")
                                      .attr("fill", "none")
                                      .attr("class", "tempText") // Class to identify and remove
                                      .style("font-size", "12px")
                                      .style("font-family", "Arial")
                                      .text(line);
                  const lineWidth = tempText.node().getComputedTextLength();
                  maxWidth = Math.max(maxWidth, lineWidth);
                  tempText.remove(); // Remove temporary text element
                });

                const textWidth = maxWidth;
                const textHeight = lines.length * 14; // Calculate height based on number of lines
        
                // Position tooltip to the right of the circle
                const rectX = xScale(d.x) + 10; // Add some space from the circle
                const rectY = yScale(d.y) - textHeight * 0;
        
                // Create tooltip background
                const tooltipRect = svg.append("rect")
                  .attr("x", rectX)
                  .attr("y", rectY)
                  .attr("width", textWidth + 10) // Add some padding
                  .attr("height", textHeight + 5) // Add some padding
                  .attr("fill", "rgba(0, 0, 0, 0.6)") // Background color
                  .attr("rx", 4) // Rounded corners
                  .attr("ry", 4) // Rounded corners
                  .attr("class", "svg-tooltip-rect");
        
                // Create tooltip text and add lines using tspans
                const tooltipText = svg.append("text")
                  .attr("x", rectX + 5) // Start text inside the background rectangle
                  .attr("y", rectY + 14) // Align text vertically within the background rectangle
                  .attr("text-anchor", "start") // Anchor text to the start (left)
                  .attr("fill", "white")
                  .style("font-size", "12px")
                  .style("font-family", "Arial")
                  .attr("class", "svg-tooltip-text");
        
                lines.forEach((line, i) => {
                  tooltipText.append("tspan")
                    .attr("x", rectX + 5) // Align tspans to the start of the text element
                    .attr("dy", i === 0 ? 0 : 14) // Add line spacing after the first line
                    .text(line);
                });
              }
            })
            .on("mouseout", function(d) {
              if (!this.clicked && !anyPointClicked) { // Check if the circle was not clicked
                d3.select(this)
                  .attr("stroke", null)
                  .attr("stroke-width", null);

                // Remove the tooltip elements
                d3.selectAll(".svg-tooltip-rect").remove();
                d3.selectAll(".svg-tooltip-text").remove();
              }
            })
            .on("click", function(event, d) {
              // Toggle the clicked state of the point
              this.clicked = !this.clicked;
            
              if (this.clicked) { // If the point is now in the clicked state, show the tooltip
                d3.select(this)
                  .attr("stroke", "black") // Color of the boundary
                  .attr("stroke-width", 2); // Width of the boundary
            
                // Tooltip text lines (same as before)
                const lines = [
                  `Cell Line: ${d.cellLine}`,
                  `Cell Type: ${d.cellType}`,
                  `${geneNameA}: ${d.x}`,
                  `${geneNameB}: ${d.y}`
                ];
            
                // Calculate width and height of the tooltip based on text (same as before)
                let maxWidth = 0;
                lines.forEach(line => {
                  const tempText = svg.append("text")
                                      .attr("fill", "none")
                                      .attr("class", "tempText")
                                      .style("font-size", "12px")
                                      .style("font-family", "Arial")
                                      .text(line);
                  const lineWidth = tempText.node().getComputedTextLength();
                  maxWidth = Math.max(maxWidth, lineWidth);
                  tempText.remove(); // Remove temporary text element
                });
            
                const textWidth = maxWidth;
                const textHeight = lines.length * 14;
            
                // Position tooltip to the right of the circle (same as before)
                const rectX = xScale(d.x) + 10;
                const rectY = yScale(d.y) - textHeight * 0;
            
                // Create tooltip background (same as before)
                const tooltipRect = svg.append("rect")
                  .attr("x", rectX)
                  .attr("y", rectY)
                  .attr("width", textWidth + 10)
                  .attr("height", textHeight + 5)
                  .attr("fill", "rgba(0, 0, 0, 0.6)")
                  .attr("rx", 4)
                  .attr("ry", 4)
                  .attr("class", "svg-tooltip-rect");
            
                // Create tooltip text and add lines using tspans (same as before)
                const tooltipText = svg.append("text")
                  .attr("x", rectX + 5)
                  .attr("y", rectY + 14)
                  .attr("text-anchor", "start")
                  .attr("fill", "white")
                  .style("font-size", "12px")
                  .style("font-family", "Arial")
                  .attr("class", "svg-tooltip-text");
            
                lines.forEach((line, i) => {
                  tooltipText.append("tspan")
                    .attr("x", rectX + 5)
                    .attr("dy", i === 0 ? 0 : 14)
                    .text(line);
                });
            
              } else { // If the point is now in the unclicked state, remove the tooltip
                d3.select(this)
                  .attr("stroke", null)
                  .attr("stroke-width", null);
            
                // Remove the tooltip elements
                d3.selectAll(".svg-tooltip-rect").remove();
                d3.selectAll(".svg-tooltip-text").remove();
              }
            
              // Update the anyPointClicked state
              anyPointClicked = d3.selectAll(".data-point").nodes().some(node => node.clicked);
            });

          // Add the X Axis
          svg.append("g")
            .attr("transform", `translate(0,${height + margin.top})`)
            .style("font-size", "18px")
            .style("font-family", "Arial")
            .call(xAxis.ticks(6));

          // Add the Y Axis
          svg.append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .style("font-size", "18px")
            .style("font-family", "Arial")
            .call(yAxis.ticks(6));

          // Add X axis label
          svg.append("text")
            .attr("text-anchor", "middle")
            .attr("x", width / 2 + margin.left)
            .attr("y", height + margin.top + 50)
            .text(`Expression levels of ${geneNameA}`)
            .style('font-family', 'Arial');

          // Add Y axis label
          svg.append("text")
            .attr("text-anchor", "middle")
            .attr("transform", `rotate(-90) translate(${-margin.top - height / 2}, ${margin.left - 50})`)
            .text(`Expression levels of ${geneNameB}`)
            .style('font-family', 'Arial');

          // Calculate Pearson correlation coefficient
          const xValues = points.map(d => d.x);
          const yValues = points.map(d => d.y);
          const r = pearsonCorrelation(xValues, yValues);

          // Calculate regression line parameters
          const { slope, intercept } = linearRegression(xValues, yValues);

          // Draw the trend line
          svg.append("line")
          .attr("class", "trendline")
          .attr("x1", xScale(d3.min(xValues)))
          .attr("y1", yScale(slope * d3.min(xValues) + intercept))
          .attr("x2", xScale(d3.max(xValues)))
          .attr("y2", yScale(slope * d3.max(xValues) + intercept))
          .style("stroke", "red")
          .style("stroke-width", 1);

          // Display Pearson correlation coefficient
          svg.append("text")
            .attr("class", "pearson")
            .attr("x", width + margin.left)
            .attr("y", margin.top)
            .text(`r = ${r.toFixed(2)}`)
            .style("font-size", "18px")
            .style("font-family", "Arial");
          

          // Dimensions of the SVG container
          const svgWidth = svg.node().getBoundingClientRect().width;
          const svgHeight = svg.node().getBoundingClientRect().height;

          // Legend dimensions and position
          const legendPosX = svgWidth - 280; // Adjust this value as needed, 150px from the right edge
          const legendPosY = 70; // Y position of the legend, adjust as needed
          const legendItemHeight = 10; // Height of each item in the legend
          const legendItemWidth = 10; // Width of the colored part of each legend item
          const legendSpacing = 5; // Spacing between legend items

          // Create a legend group
          const legend = svg.append("g")
            .attr("class", "legend")
            .attr("transform", `translate(${legendPosX},${legendPosY})`);

          let activeType = null; // Variable to keep track of the active legend item
          
          if (cellTypeToggleIsOn){

            // Add legend items
          cellTypes.forEach((type, index) => {
            // Legend item group
            const legendItem = legend.append("g")
              .attr("class", "legend-item")
              .datum(type)  // Bind the cellType or type data to the legend item
              .attr("transform", `translate(0, ${index * (legendItemHeight + legendSpacing)})`);

            // Colored rectangle or circle for each cell type
            legendItem
              .append("path") // Use path for different shapes
              .attr("d", d3.symbol().type(d => symbols[Math.floor(cellTypes.indexOf(type) / 10)])) // Set shape based on cellType
              .style("fill", colorScale(type))
              .on("mouseover", function() {
                if (activeType !== null && activeType !== type) return; // Ignore if another item is active
          
                // Reduce the opacity of all points not matching the hovered legend item's cell type
                svg.selectAll(".data-point")
                  .style("opacity", d => (d.cellType === type ? 1 : 0.1));
          
                // Change the opacity of other legend items
                legend.selectAll(".legend-item path")
                  .style("opacity", d => (d === type ? 1 : 0.1));
              })
              .on("mouseout", function() {
                if (activeType !== null) return; // Ignore if an item is active
          
                // Restore full opacity to all points
                svg.selectAll(".data-point").style("opacity", 1);
          
                // Restore full opacity to all legend items
                legend.selectAll(".legend-item path").style("opacity", 1);
              })
              .on("click", function() {
                // Toggle the active state
                activeType = activeType === type ? null : type;
          
                // Update the opacity of data points based on the active state
                svg.selectAll(".data-point")
                  .style("opacity", d => (activeType === null || d.cellType === activeType ? 1 : 0.1));
          
                // Update the opacity of legend items based on the active state
                legend.selectAll(".legend-item path")
                  .style("opacity", d => (activeType === null || d === activeType ? 1 : 0.1));
              });

            // Text label for each cell type
            legendItem.append("text")
              .attr("x", legendItemWidth + legendSpacing * 0.1)
              .attr("y", legendItemHeight - legendSpacing * 1.35)
              .text(type)
              .style("font-size", "10px")
              .style("font-family", "Arial");
          });

          }

            const filename=`protein_co-expression_of_${geneNameA}-${geneNameB}`

            const saveOptions = [
    
            { format: "svg", label: "Save as SVG" },
            { format: "jpeg", label: "Save as JPEG" },
            { format: "png", label: "Save as PNG" },
            { format: "pdf", label: "Save as PDF" }
    
            ];

            const calcWidth = 500;
            
            const calcHeight = 500;

            if(d3Container.current){

                saveButton(btncontainerRef, saveOptions, d3Container,
                    calcWidth,
                    calcHeight,
                    filename);

            }
                  
        }
            
      }, [cellTypeToggleIsOn, loading, data, d3Container.current]);
  
  if (loading) {
    return <div>
        Loading...</div>;
  }

  return(
    <>
    
  <table class="general-properties-table">
  <tbody class="tbody">
  <tr class="row">
      <td class="column"><strong>Protein co-expression:</strong></td>
      <td class="column">
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 0, padding: 0 }}>
    Sort by cell type
    <div className="flip-switch" style={{ padding: 5 }}>
        <input
            type="checkbox"
            className="flip-switch-checkbox"
            id="domainFlipSwitch"
            checked={cellTypeToggleIsOn}
            onChange={handleCellTypeToggle}
        />
        <label className="flip-switch-label" htmlFor="domainFlipSwitch">
            <div className="flip-switch-inner" />
            <div className="flip-switch-switch" />
        </label>
    </div>
</div>
        <svg
          className="d3-component"
          width={800}
          height={500}
          ref={d3Container}>
        </svg>
        <div className='savebtn_container' ref={btncontainerRef}
          style={{
              position:"relative",
          }}>
        </div>
        
      </td>
    </tr>
    <tr class="row">
      <td class="column"><strong>PAE:</strong></td>
      <td class="column">
        <MoimPAE
        fileName = {fileName}
        geneNameA = {geneNameA}
        geneNameB = {geneNameB}
        lengthA = {lengthA}
        lengthB = {lengthB}
        /></td>
    </tr>
    <tr class="row">
      <td class="column"><strong>Organism:</strong></td>
      <td class="column"><em>Homo sapiens</em></td>
    </tr>
    <tr class="row">
      <td class="column"><strong>Protein name A, UniProt:</strong></td>
      <td class="column">{proteinNameA}</td>
    </tr>
    <tr class="row">
      <td class="column"><strong>Gene name A, UniProt:</strong></td>
      <td class="column">{geneNameA}</td>
    </tr>
    <tr class="row">
      <td class="column"><strong>UniProt ID A:</strong></td>
      <td class="column"><a href={`https://www.uniprot.org/uniprotkb/${uniProtIDA}/entry`}>{uniProtIDA}</a></td>
    </tr>
    <tr class="row">
      <td class="column"><strong>Protein name B, UniProt:</strong></td>
      <td class="column">{proteinNameB}</td>
    </tr>
    <tr class="row">
      <td class="column"><strong>Gene name B, UniProt:</strong></td>
      <td class="column">{geneNameB}</td>
    </tr>
    <tr class="row">
      <td class="column"><strong>UniProt ID B:</strong></td>
      <td class="column"><a href={`https://www.uniprot.org/uniprotkb/${uniProtIDB}/entry`}>{uniProtIDB}</a></td>
    </tr>
    <tr class="row">
      <td class="column"><strong>Pearson correlation:</strong></td>
      <td class="column">{pearson}</td>
    </tr>
    <tr class="row">
      <td class="column"><strong>AF-multimer max 0.8 * ipTM + 0.2 * pTM:</strong></td>
      <td class="column">{maxScore}</td>
    </tr>
  </tbody>
</table>
</>
);
}

export default MoimGeneral;