export default function Version(){
    return (
        <>

        <div className="subtitle">Version</div>
    
        <div className="link">
    
            <a href="#" className="disabled">spatiomics.org v0.9.0 last updated 24.02.16</a>
        
        </div>
      
        </>
    );
}