import React, { useEffect, useRef, useState } from 'react';
import  { ReactComponent as MoimLogoSVG }from './svg/moim_logo_ver0.0.2.svg';
import './MoimTitle.css';

export default function MoimTitle() {
    

      return (
        <div className="titlecontainer">
            <MoimLogoSVG/>
        </div>
      );
  }
  
 ; 