import * as THREE from 'icn3d/three'
import $ from 'jquery'
import 'jquery-ui-bundle';

import 'jquery-ui-bundle/jquery-ui.min.css';

global.THREE = THREE
global.$ = $
global.jQuery = $

console.log("icn3d dependencies loaded");