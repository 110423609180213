import React, { useState, useEffect, useRef, useCallback} from 'react';
import * as d3 from 'd3';
import getcolor from './getcolor';
import saveButton from './saveButton';
import './DomainSVG.css';
import evalTManno from './evalTManno';

export default function DomainSVG({genename,
        sites,
        style,
        tm_manual,
        tm_uniprot,
        tm_deeptmhmm,
        tm_tmbed,
        sequence,
        localization_mitoatlas,
        domain}){

    const [domainToggleIsOn, setDomainToggleIsOn] = useState(true);

    function handleDomainToggle() {
        setDomainToggleIsOn(!domainToggleIsOn);
    }

    const svgRef=useRef(null);

    const svgcontainerRef=useRef(null);

    const btncontainerRef=useRef(null);

    const [selectedTM, setSelectedTM] = useState(""); // keep track of selected TM source

    const [tmOptions, setTMOptions] = useState([]);

    const [isoption, setIsoption] = useState(false);

    function createTextWithRect(container, margin, x, y, text, anchor = "middle", fontSize = 10, fontFamily = "Arial") {
        // Create a group to hold the text and the rectangle
        const textGroup = container.append("g").attr("class","txtbox")
            .attr("transform", `translate(${(x+margin.left)}, ${(y+margin.top)})`);
    
        // Add text to the group
        const textElement = textGroup.append("text")
            .text(text)
            .attr("font-size", fontSize)
            .attr("font-family", fontFamily)
            .attr("text-anchor", anchor);
    
        // Calculate the bounding box of the text
        const textBox = textElement.node().getBBox();
    
        // Add a rectangle around the text
        textGroup.append("rect")
            .attr("width", textBox.width -2)
            .attr("height", textBox.height -2)
            .attr("x", -(textBox.width -2) / 2)
            .attr("y", -(textBox.height -2) / 2 - 5)
            .attr("fill", "white")
            .style("fill-opacity", 0.8);
        
        textElement.raise();
    
        return textGroup;
    }

    function createLinkTextWithRect(container, margin, x, y, text, anchor = "middle", link,  fontSize = 10, fontFamily = "Arial") {
        // Create a group to hold the text and the rectangle
        const textGroup = container.append("a").attr("class","txtbox")
            .attr("xlink:href", link) // Set the hyperlink
            .attr("target", "_blank") // Open in a new tab
            .attr("transform", `translate(${(x+margin.left)}, ${(y+margin.top)})`);
    
        // Add text to the group
        const textElement = textGroup.append("text")
            .text(text)
            .attr("font-size", fontSize)
            .attr("font-family", fontFamily)
            .attr("text-anchor", anchor);
    
        // Calculate the bounding box of the text
        const textBox = textElement.node().getBBox();
    
        // Add a rectangle around the text
        textGroup.append("rect")
            .attr("width", textBox.width -2)
            .attr("height", textBox.height -2)
            .attr("x", -(textBox.width -2) / 2)
            .attr("y", -(textBox.height -2) / 2 - 5)
            .attr("fill", "white")
            .style("fill-opacity", 0.8);
        
        textElement.raise();
    
        return textGroup;
    }

    //ticks for upper side
    function createUpperTick(container, margin, x1, y1, x2, y2) {

        const tick = container.append("line")
            .attr("x1", x1 + margin.left)
            .attr("y1", y1 + margin.top)
            .attr("x2", x2 + margin.left)
            .attr("y2", y2 + margin.top)
            .attr("stroke", "black")
            .attr("stroke-width", 1);

        return tick
    }

    useEffect(()=>{
        const options = [];
        if (tm_manual.length>0) {
            const scores = evalTManno({sites:sites, TMs:tm_manual});
            console.log(scores);
            options.push({ value: "manual", 
                label: `TM from Manual annotation, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2, 
                maxscore: Math.max(scores.score1, scores.score2)});
        }
        if (tm_uniprot.length>0) {
            const scores = evalTManno({sites:sites, TMs:tm_uniprot});
            console.log(scores);
            options.push({ value: "uniprot", 
                label: `TM from UniProt, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2, 
                maxscore: Math.max(scores.score1, scores.score2)});
            //setSelectedTM('uniprot'); // set selectedTM to 'uniprot' if parsedTMuniprot is not empty
        }
        if (tm_deeptmhmm.length>0) {
            const scores = evalTManno({sites:sites,TMs:tm_deeptmhmm});
            options.push({ value: "deeptmhmm",
                label: `TM from DeepTMHMM, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2,
                maxscore: Math.max(scores.score1, scores.score2)});
        }
        if (tm_tmbed.length>0) {
            const scores = evalTManno({sites:sites,TMs:tm_tmbed});
            options.push({ value: "tmbed", 
                label: `TM from TMbed, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2,
                maxscore: Math.max(scores.score1, scores.score2)});
        } 
        options.push({value: 'none',
        label: 'no TM domain annotation',
        maxscore: -0.01});

        if( selectedTM === '' && options.length > 0 ){
        //draw the topology of the highest scoring protein with the desired direction
        const optionWithHighestScore = options.reduce((acc, cur) => {
            return cur.maxscore > acc.maxscore ? cur : acc;
            });

        setSelectedTM(optionWithHighestScore.value);
        
        setIsoption(true);
        
        }
        // set the TM options when the component mounts
        const updatedOptions = options.slice(); // create a copy of the options array

        setTMOptions(updatedOptions);

    },[ genename, selectedTM, sites, tm_manual, tm_deeptmhmm, tm_tmbed, tm_uniprot ]);

    const handleTMChange = useCallback((event) => {

        setSelectedTM(event.target.value);

        console.log(tmOptions);

        if(tmOptions.length > 0){

            const selected = tmOptions.find((d) => d.value === event.target.value);

        }
        
      }, [ tmOptions ]);

    const [svgWidth, setSvgWidth] = useState(200); // Default width
    const [svgHeight, setSvgHeight] = useState(200); // Default 

    useEffect(()=>{

        const labelNames=['inconclusive','matrix','IMM','IMS','OMM','OMM periphery','non-mito'];

        if(tmOptions.length<1) return;
        let usedTM = [] ;
        let tmScore = 0 ;
        if(selectedTM==='manual'){
            usedTM = tm_manual;

            tmScore = Math.max(tmOptions.find((d) => d.value === 'manual').score1, tmOptions.find((d) => d.value === 'manual').score2 );

        } else if(selectedTM==='uniprot'){
            usedTM = tm_uniprot;

            tmScore = Math.max(tmOptions.find((d) => d.value === 'uniprot').score1, tmOptions.find((d) => d.value === 'uniprot').score2 );
            

        } else if(selectedTM==='deeptmhmm'){

            usedTM = tm_deeptmhmm;

            tmScore = Math.max(tmOptions.find((d) => d.value === 'deeptmhmm').score1, tmOptions.find((d) => d.value === 'deeptmhmm').score2 );

        } else if(selectedTM==='tmbed'){

            usedTM = tm_tmbed;

            tmScore = Math.max(tmOptions.find((d) => d.value === 'tmbed').score1, tmOptions.find((d) => d.value === 'tmbed').score2 );
        }

        let sublocal=0;//0 equals inconclusive. 0 matrix 1 IMM 2 IMS 3 OMM-TM 4 OMM 5 nonmito
        
        if(localization_mitoatlas.substring(0, 6)==='matrix'){
          sublocal=1
        } else if(localization_mitoatlas.substring(0, 28)==='mitochondrial inner membrane'){
          sublocal=2
        } else if(localization_mitoatlas.substring(0, 3)==='IMS'){
          sublocal=3
        } else if(localization_mitoatlas.substring(0, 28)==='mitochondrial outer membrane'){
          sublocal=4
        } else if(localization_mitoatlas.substring(0, 3)==='OMM'){
          sublocal=5
        } else if(localization_mitoatlas.substring(0, 8)==='non-mito'){
          sublocal=6
        }
        const labelColors = [getcolor(style,'inconclusive','lighthex'),
        getcolor(style,'matrix','lighthex'),
        "url(#immGradient)",
        getcolor(style,'IMS','lighthex'),
        "url(#ommGradient)",
        getcolor(style,'OMM','lighthex'),
        getcolor(style,'non-mito','lighthex'),
        getcolor(style,'tm-odd','lighthex'),
        getcolor(style,'tm-even','lighthex')];

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();
        
        const boxHeight=15;

        const margin = { top : 100 , bottom : 70 , left : 70 , right : 70 } ;

        const container=svg.append('g');

        setSvgWidth(sequence.length+300);
        setSvgHeight(margin.top + boxHeight + margin.bottom);

        const protRect=container.append("rect")
            .attr("x", margin.left)
            .attr("y", margin.top)
            .attr("height", boxHeight)
            .attr("width", sequence.length)
            //.attr("y",height*0.4)
            .attr("fill", "#b0b0b0")
            .attr("stroke","black")
            .attr("stroke-width",2);

        const nTermText = container.append("text").text("Nterm")
            .attr("x", margin.left - 5)
            .attr("y", margin.top + 15)
            .attr("font-size", 10)
            .attr("font-family","Arial")
            .attr("text-anchor", "end");  

        const cTermText = container.append("text").text("Cterm")
            .attr("x", margin.left + sequence.length + 5)
            .attr("y", margin.top + 15)
            .attr("font-size", 10)
            .attr("font-family","Arial")
            .attr("text-anchor", "start");
        
        createUpperTick(container, margin, 0, 0, 0, -5);
        
        createTextWithRect(container, margin, 0, -8, 1, "end");
        
        createUpperTick(container, margin, sequence.length, 0, sequence.length, -5);

        createTextWithRect(container, margin, sequence.length, -8, sequence.length, "start");

        //draw domain boxes and ticks and labels

        const colorPalette = ['#FEDC97', '#06588a', '#28666e', '#7c9885', '#b5b682']; // Add as many colors as needed

        domain.domains.forEach((domainFrag, index) => {

            if(domainToggleIsOn && domainFrag.type === 'homologous_superfamily' || !domainToggleIsOn && domainFrag.type === 'domain'){

                const domainColor = colorPalette[index % colorPalette.length];
        
                const domainRect = container.append("rect")
                    .attr("x", margin.left + domainFrag.start - 1)
                    .attr("y", margin.top)
                    .attr("height", boxHeight)
                    .attr("width", domainFrag.end - domainFrag.start + 1)
                    .attr("fill", domainColor)
                    .attr("stroke", "black")
                    .attr("stroke-width", 2);
                
                // Create text with surrounding rectangle for domain
                createTextWithRect(container, margin, (domainFrag.end + domainFrag.start + 1) / 2, 15 + boxHeight, domainFrag.name);

                var link = 'https://www.ebi.ac.uk/interpro/entry/InterPro/' + domainFrag.interproid;

                createLinkTextWithRect(container, margin, (domainFrag.end + domainFrag.start + 1) / 2, 30 + boxHeight, domainFrag.interproid, 'middle', link);

                createUpperTick(container, margin, (domainFrag.end + domainFrag.start + 1) / 2, boxHeight,
                (domainFrag.end + domainFrag.start + 1) / 2, boxHeight+5);

                createUpperTick(container, margin, domainFrag.start-1, 0, domainFrag.start-1, -5);

                createTextWithRect(container, margin, domainFrag.start - 1, -10, domainFrag.start);
                
                createUpperTick(container, margin, domainFrag.end, 0, domainFrag.end, -5);
                
                createTextWithRect(container, margin, domainFrag.end, -10, domainFrag.end);
            
            } 
        });

        //draw tm domains and ticks and labels

        const tmColorPalette = ['#7dfc74', '#489942']; //tm domains color palette

        usedTM.forEach((tm, index) => {
            const tmColor = tmColorPalette[index % tmColorPalette.length];
            
            const tmRect = container.append("rect")
                .attr("x", margin.left + tm.start - 1)
                .attr("y", margin.top)
                .attr("height", boxHeight)
                .attr("width", tm.end - tm.start + 1)
                .attr("fill", tmColor)
                .attr("stroke", "black")
                .attr("stroke-width", 2);
            
            createTextWithRect(container, margin, (tm.end + tm.start + 1) / 2, 40 + 15*(index%2) + boxHeight, "TM" + (index+1));

            createUpperTick(container, margin, (tm.end + tm.start + 1) / 2, boxHeight,
                (tm.end + tm.start + 1) / 2, 30 + 15*(index%2) + boxHeight);

            createUpperTick(container, margin, tm.start-1, 0, tm.start-1, -5 - 10*(index%2));

            createTextWithRect(container, margin, tm.start - 1, -10 - 10*(index%2), tm.start);
            
            createUpperTick(container, margin, tm.end, boxHeight, tm.end, boxHeight + 5 + 10*(index%2));
            
            createTextWithRect(container, margin, tm.end, boxHeight + 15 + 10*(index%2), tm.end);
        });

        //draw labeled sites and ticks and labels

        console.log(sites);

        let dy = 0;
        let count = 0;
        sites.forEach((site, index) => {
            const siteColor = getcolor(style, site.Localization_mitoatlas,'hex')

            if (index > 0){

                if (( -parseInt(sites[index-1].site_id.split('_')[1]) + parseInt(site.site_id.split('_')[1])) < 6) {

                    if(dy == (margin.top + boxHeight/2 - 8)){

                        dy = margin.top + boxHeight/2;

                    } else {

                        dy = margin.top + boxHeight/2 - 8;

                    }
                    
                } else {

                    dy = margin.top + boxHeight/2;

                }

            } else {

                count = 0;

                dy = margin.top + boxHeight/2;

            }

            if (index > 0){

                if (( -parseInt(sites[index-1].site_id.split('_')[1]) + parseInt(site.site_id.split('_')[1])) < 20){

                    count +=1 ;

                } else {

                    count = 0;

                }

            } 


            const siteCircle = container.append("circle")
                .attr("cx", margin.left + parseInt(site.site_id.split('_')[1]) - 1)
                .attr("cy", dy)
                .attr("r", 4)
                .attr("fill", siteColor)
                .attr("stroke", "black")
                .attr("stroke-width", 2);

            createUpperTick(container, margin, parseInt(site.site_id.split('_')[1]) - 1, (dy-margin.top)-3,
                parseInt(site.site_id.split('_')[1]) - 1, -30-10*(count%5));

            let site_id = site.site_id.split('_')[1];

            if (site.enzyme == "APEX2"){
                site_id = "Y"+site.site_id.split('_')[1];
            } else if (site.enzyme == "TurboID" || site.enzyme == "BioID"){
                site_id = "K"+site.site_id.split('_')[1];
            } 

            createTextWithRect(container, margin, parseInt(site.site_id.split('_')[1]) - 1,
                -30-10*(count%5), site_id);
        });

        container.selectAll(".txtbox").raise();

        const filename=`mitoatlas_topology_of_${genename}`

        const saveOptions = [

        { format: "svg", label: "Save as SVG" },
        { format: "jpeg", label: "Save as JPEG" },
        { format: "png", label: "Save as PNG" },
        { format: "pdf", label: "Save as PDF" }

        ];
        const calcWidth = sequence.length + 300;
        const calcHeight = margin.top + boxHeight + margin.bottom;
        if(svgRef.current){
            saveButton(btncontainerRef, saveOptions, svgRef,
                calcWidth,
                calcHeight,
                filename);
        }

    },[ selectedTM,
        sites, 
        domainToggleIsOn,
        tmOptions,
        tm_manual, 
        tm_deeptmhmm, 
        tm_tmbed, 
        tm_uniprot, 
        genename,
        localization_mitoatlas, 
        sequence, 
        style ]);

    return (<>
        {isoption &&
        <div style={{display:"flex",
        flexDirection:"column",
        flexFlow:"column wrap"}}
        ref={svgcontainerRef}>
            <select
                className="iframe-select"
                value={selectedTM}
                onChange={handleTMChange}
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '0px',
                    padding: '8px 16px',
                    fontSize: '20px',
                    fontFamily: 'Arial',
                    cursor: 'pointer',
                    position:'relative',
                    top:0,
                    width:400,
                }}
            >
                {tmOptions.map(options => (
                    <option key={options.value} value={options.value}>{options.label}</option>
                ))}
            </select>
            <div className="flip-switch_container"
            style={{
                display: "flex",
                flexDirection:"row",
                flexFlow:"row wrap"
            }}
            >
                <div style = {{marginRight:0,
                    padding: 5}}>Use IPR Domain</div>
                <div className="flip-switch"
                    style = {{ padding: 5}}>
                <input
                    type="checkbox"
                    className="flip-switch-checkbox"
                    id="domainFlipSwitch"
                    checked={domainToggleIsOn}
                    onChange={handleDomainToggle}
                />
                <label className="flip-switch-label" htmlFor="domainFlipSwitch">
                    <div className="flip-switch-inner" />
                    <div className="flip-switch-switch" />
                </label>
                </div>
                <div style = {{marginRight:0,
                    padding: 5}}>Use IPR Superfamily</div>
            </div>
            <div style={{ width: '100%', height: 'auto' }}>
                <svg ref={svgRef} viewBox={`0 0 ${svgWidth} ${svgHeight}`} >
                </svg>
            </div>
            <div className='savebtn_container' ref={btncontainerRef}
                style={{
                    position:"relative",
                }}>
            </div>
        </div>}</>
    );
}  
