export default function Dev(){
    return (
        <>

        <div className="subtitle">Developer</div>
        
        <div className="link">
        
            <a href="#" className="disabled">Jiwoong Kang. contact kjw98snu@snu.ac.kr</a>
        
        </div>
      
        </>
    );
}