import React, { useState, useEffect} from 'react';

import { useLocation, Link } from 'react-router-dom';

import './SearchResult.css';

import SearchBar from './SearchBar';

import axios from 'axios';

import { debounce } from 'lodash';

import getcolor from './getcolor';

import DynamicTitle from './DynamicTitle';

export default function SearchResult() {

  const [style, setStyle] = useState([]);

  const [labelColors, setLabelColors]=useState([]);

  const [styleloading, setStyleLoading] = useState(false);

  const currentHost = window.location.hostname;

  let subDomain = '';

  if (currentHost.split('.').length > 2 ) {

    subDomain = currentHost.split('.')[0];

  }

  const source = axios.CancelToken.source();

    useEffect(() => {

      const debouncedQuery = debounce(() => {

      setStyleLoading(true);

      const queries=[

        axios.get('https://spatiomics.org/api/style',),

      ];
  
      Promise.all(queries)

            .then(([response]) => {

              setStyle(response.data);

            })

            .catch(error => {

              console.error(`there was an error communicating to the API: ${error}`);

            })

            .finally(() => {

              setStyleLoading(false);

            });

          }, 100);
  
          debouncedQuery();

          return () => {

            source.cancel();

          };

        }, []);

  function getcolormitoatlas(localization){
    //parse mitoaltas localization
    //0 equals inconclusive. 0 matrix 1 IMM 2 IMS 3 OMM-TM 4 OMM 5 nonmito

    if(localization.substring(0, 6)==='matrix'){

      return(labelColors[1]);

    } else if(localization.substring(0, 28)==='mitochondrial inner membrane'){

      return(labelColors[2]);

    } else if(localization.substring(0, 3)==='IMS'){

      return(labelColors[3]);

    } else if(localization.substring(0, 28)==='mitochondrial outer membrane'){

      return(labelColors[4]);

    } else if(localization.substring(0, 3)==='OMM'){

      return(labelColors[5]);

    } else if(localization.substring(0, 8)==='non-mito'){

      return(labelColors[6]);

    }

    return(labelColors[0]);

    }

    useEffect(()=>{

      if(style.length==0){return};

      setLabelColors([`${getcolor(style,'inconclusive','lighthex')},${getcolor(style,'inconclusive','lighthex')}`,

      `${getcolor(style,'matrix','lighthex')},${getcolor(style,'matrix','lighthex')}`,

      `${getcolor(style,'matrix','lighthex')},${getcolor(style,'matrix','lighthex')} 40%,${getcolor(style,'IMS','lighthex')} 60%,${getcolor(style,'IMS','lighthex')}`,
      
      `${getcolor(style,'IMS','lighthex')},${getcolor(style,'IMS','lighthex')}`,
      
      `${getcolor(style,'IMS','lighthex')},${getcolor(style,'IMS','lighthex')} 40%,${getcolor(style,'OMM','lighthex')} 60%,${getcolor(style,'OMM','lighthex')}`,
      
      `${getcolor(style,'OMM','lighthex')},${getcolor(style,'OMM','lighthex')}`,
      
      `${getcolor(style,'non-mito','lighthex')},${getcolor(style,'non-mito','lighthex')}`,]);

    },[styleloading]);

  const { state } = useLocation();

  const searchResults = state?.results || [];

  if ( searchResults=="notfound" ){

    return (

      <><div className="App">

        <header className="App-header">

        <DynamicTitle />
        
        </header>

      </div>

      <div className="title">

        There were 0 hits. Please try again.

      </div>

      <SearchBar />

      <div>

      </div>

      </>
    );
  } else if ( searchResults === "tooshort" ) {

    return (

      <><div className="App">

        <header className="App-header">

        <DynamicTitle />

        </header>

      </div>

      <div className="title">Keyword is too short. Please try again.</div>

      <SearchBar />

      <div>

      </div>
      </>
    );
  }else { 

    return (

    <><div className="App">

      <header className="App-header">

      <DynamicTitle />

      </header>

    </div>

    <div className="title">{searchResults.length} hit(s) found</div>

    <SearchBar />

    <div>

    <div className="searchResultWrap">

      <div className="searchResultList">

          { subDomain !== 'moim' && searchResults.map(result => {

          const style = subDomain !== 'sarscov2' ? {

            backgroundImage: `linear-gradient(to right, ${getcolormitoatlas(result.Localization_mitoatlas)})`,

            textAlign: "",

          } : {

            textAlign: "",

          };

          return (

            <Link to={`/${result.UniProt_ID}`}>

              <div className="searchResultItem" 

                  key = {result.UniProt_ID} 

                  style = {style}>

                    {result.Genename}     

              </div>

            </Link>
          )
          })}

          { subDomain === 'moim' && searchResults.map(result => {

          const style = {

            textAlign: "",

          };

          return (

            <Link to={`/${result.fileName}`}>

              <div className="searchResultItem" 

                  key = {result.fileName} 

                  style = {style}>

                  {result.ProteinA_GeneName + '-' + result.ProteinB_GeneName + ', 0.8 * ipTM + 0.2 * pTM :' + result.maxAfmmScore}

              </div>

            </Link>
          )
          })}


        </div>

      </div>

    </div>

    </>
  );
  }
}
