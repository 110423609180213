/* eslint-disable no-restricted-globals */
import React, { useState, useEffect} from 'react';

import './SearchBar.css';

import useAutoSearch from './autosearch';

import { useNavigate, Link } from 'react-router-dom';

import axios from 'axios';

import getcolor from './getcolor';

import { debounce } from 'lodash';

function SearchBar() {

  const [style, setStyle] = useState([]);

  const [labelColors, setLabelColors]=useState([]);

  const [styleloading, setStyleLoading] = useState(false);

  const source = axios.CancelToken.source();

  const currentHost = window.location.hostname;

  let subDomain = '';

  if (currentHost.split('.').length === 3 ) {

    subDomain = currentHost.split('.')[0];

  }

  let btnStyle = { height:41.2667 };

  if (subDomain === 'sarscov2') {

    btnStyle.backgroundColor = '#7ade95'

  }

  useEffect(() => {
    
    const debouncedQuery = debounce(() => {

    setStyleLoading(true);

    const queries=[

      axios.get('https://spatiomics.org/api/style',),

    ];

    Promise.all(queries)

          .then(([response]) => {

            setStyle(response.data);
          })

          .catch(error => {

            console.error(`there was an error communicating to the API: ${error}`);

          })

          .finally(() => {

            setStyleLoading(false);

          });

        }, 100);

        debouncedQuery();

        return () => {

          source.cancel();

        };

      }, []);

    const [ query, setQuery ] = useState('');

    let [results, loading] = useAutoSearch(query);

    const navigate = useNavigate();

    const handleInputChange = event => {

        setQuery(event.target.value);

    };
    
    const handleSubmit = event => {

      event.preventDefault();

      if( query < 2 ){

        navigate('/searchresult', { state: { results: 'tooshort' } });

      } else {

        event.preventDefault();

        let getQuery = `https://spatiomics.org/api/search?search=${query}`
        //filter sarscov2 proteins if subdomain is sarscov2
        if(subDomain == 'sarscov2'){

          getQuery = `https://spatiomics.org/api/search?search=${query}&filter=sarscov2`

        } else if(subDomain == 'moim'){

          getQuery = `https://spatiomics.org/api/search?search=${query}&filter=moim`

        }
        axios.get(getQuery)

        .then(response => {

        const searchResults = response.data;

        if (searchResults.length === 1) {
          
          if (subDomain !== 'moim') {

            navigate(`/${searchResults[0].UniProt_ID}`);

          } else if (subDomain === 'moim') {
            
            navigate(`/${searchResults[0].fileName}`);

          }

        } else if (searchResults.length > 0) {

          if (subDomain !== 'moim') {

            const exactMatch = searchResults.find(result => result.UniProt_ID.toLowerCase()=== query.toLowerCase() || result.Genename.toLowerCase() === query.toLowerCase());
          
            if ( exactMatch ) {
            
              navigate(`/${exactMatch.UniProt_ID}`);
            
            } else {

              navigate('/searchresult', { state: { results: searchResults } });
            
            }

          } else if (subDomain === 'moim') {

            navigate('/searchresult', { state: { results: searchResults } });

          }
        
        } else {
        
          navigate('/searchresult', { state: { results: 'notfound' } });
        
        }
          setQuery('');
          event.target.reset();
        
        })
          .catch(error => {

            console.error(error);

          });
      }
    };

    let hitsDescription = '';

    if ( results ) {

      if ( results.length >= 5 ) {

      hitsDescription = `5 or more hits found`;

      results = results.slice(0, 5);

      } else if ( query != '' && 0 < results.length && results.length < 5 ){

      hitsDescription = `${results.length} hit(s) found`;

      } else if ( query.length == 0 ){

        hitsDescription = ``;
  
      } else if ( query.length <= 2 ){

        hitsDescription = `longer keyword required`;
  
      }

    }

    function getcolormitoatlas(localization){//parse mitoaltas localization

    let sublocal=0;//0 equals inconclusive. 0 matrix 1 IMM 2 IMS 3 OMM-TM 4 OMM 5 nonmito

    if(localization.substring(0, 6)==='matrix'){

      return(labelColors[1]);

    } else if(localization.substring(0, 28)==='mitochondrial inner membrane'){

      return(labelColors[2]);

    } else if(localization.substring(0, 3)==='IMS'){

      return(labelColors[3]);

    } else if(localization.substring(0, 28)==='mitochondrial outer membrane'){

      return(labelColors[4]);

    } else if(localization.substring(0, 3)==='OMM'){

      return(labelColors[5]);

    } else if(localization.substring(0, 8)==='non-mito'){

      return(labelColors[6]);
    }
    return(labelColors[0]);
    }

    useEffect(()=>{

      if(style.length==0){return};

      setLabelColors([`${getcolor(style,'inconclusive','lighthex')},${getcolor(style,'inconclusive','lighthex')}`,
      
      `${getcolor(style,'matrix','lighthex')},${getcolor(style,'matrix','lighthex')}`,
      
      `${getcolor(style,'matrix','lighthex')},${getcolor(style,'matrix','lighthex')} 40%,${getcolor(style,'IMS','lighthex')} 60%,${getcolor(style,'IMS','lighthex')}`,
      
      `${getcolor(style,'IMS','lighthex')},${getcolor(style,'IMS','lighthex')}`,
      
      `${getcolor(style,'IMS','lighthex')},${getcolor(style,'IMS','lighthex')} 40%,${getcolor(style,'OMM','lighthex')} 60%,${getcolor(style,'OMM','lighthex')}`,
      
      `${getcolor(style,'OMM','lighthex')},${getcolor(style,'OMM','lighthex')}`,
      
      `${getcolor(style,'non-mito','lighthex')},${getcolor(style,'non-mito','lighthex')}`,]);

    },[styleloading]);
    

    return <><div className="searchBarWrap">

        <form className="searchBar" onSubmit={handleSubmit}>

          <input

            className="input"

            type="text"

            placeholder="UniProtID or Genename"

            onChange={handleInputChange}

            onInput={handleInputChange}

            defaultvalue=''

          />
          <button className="search-btn" 
          
          type="submit"
          
          style = {btnStyle}

          >Search

          </button>

        </form>

      </div>

      <div className="searchResultWrap"

        style = {{

          zIndex: 100,

        }}

        >

        {loading||styleloading && <div className="searchResultItem">Loading...</div>}

        {results && results.length > 0 && (

          <div className="searchResultList">
            { subDomain !== 'moim' && results.map(result => (

            <Link to={`/${result.UniProt_ID}`}>

              <div 
                
                className="searchResultItem" key={result.UniProt_ID}

                style = {subDomain === 'mitoatlas' ? {
                  
                  backgroundImage:`linear-gradient(to right, ${getcolormitoatlas(result.Localization_mitoatlas)})`,

                  textAlign:"",

                  } : {
                  
                    backgroundImage:``,

                    textAlign:"",

                  }}
              >

                {result.Genename}

              </div>

            </Link>

            ))}   
            { subDomain ==='moim' && results.map(result => (

              <Link to={`/${result.fileName}`}>

                <div 
                  
                  className="searchResultItem" key={result.fileName}

                  style = {{
                    
                      backgroundImage:``,

                      textAlign:"",

                    }}
                >

                  {result.ProteinA_GeneName + '-' + result.ProteinB_GeneName + ', 0.8 * ipTM + 0.2 * pTM :' + result.maxAfmmScore}

                </div>

              </Link>

            ))} 

          </div>
        )}

        {hitsDescription && (

          <div className="searchResultItem">{hitsDescription}</div>

        )}

    </div>

    </>
}

export default SearchBar;
