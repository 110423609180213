import React, { useEffect, useState} from "react";
import "./icn3d_dependencies";
import * as icn3d from 'icn3d/module';
import "../node_modules/icn3d/css/icn3d.css";

function  Icn3dViewer({ uniprotID, initCommand, width, height, moreCommand, commandIndex }) {
  //icn3dobj to handle commands with after initial load
  const [icn3dobj, setIcn3dobj] = useState(null);
  //track load state of viewer to only execute commands after load.
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    setLoaded(false);

    const cfg = {

      divid: 'viewer',

      mobilemenu: true,

      width,

      height,

      afid: uniprotID,

      afmem: 'off',

      command: initCommand,

      closepopup: true

    };

	  const icn3dui = new icn3d.iCn3DUI(cfg)

    setIcn3dobj(icn3dui);

    const loadStructure = async () => {

      await icn3dui.show3DStructure();

      setLoaded(true);

    }

    loadStructure();

  }, [uniprotID, initCommand]);
  
//detect command change from the paraent and execute them

  useEffect(() => {

  if( icn3dobj && loaded ) {

	const arrCommand = moreCommand.split(';')

	arrCommand.forEach( command => {

    //set background with setbackground() white, black, transparent

    if( command == 'set background transparent') {

      icn3dobj.icn3d.setStyleCls.setBackground('transparent');

    } else if ( command == 'set background black' ) {

      icn3dobj.icn3d.setStyleCls.setBackground('black');

    } else {

    //other normal commands with applyCommand

      icn3dobj.icn3d.applyCommandCls.applyCommand(command+' ');

    }

	})
	
  }

  }, [ commandIndex, moreCommand ] );

  return <div id="viewer"></div>;

}

export default Icn3dViewer;
