/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import axios from 'axios';
import './iBAQ.css';
import saveButton from './saveButton';

function IBAQ({ uniprotID, genename }) {
    const [ data, setData ] = useState([]);
    const [ data2, setData2 ] = useState([]);
    const [loading, setLoading] = useState(true);
    const svgRef = useRef(null);
    const source = axios.CancelToken.source();
    const containerRef = useRef(null);
    const btncontainerRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        console.log(`getting expression distribution and expression of ${uniprotID}`);
        Promise.all([
          axios.get(`https://spatiomics.org/api/hektrex_ibaq?uid=${uniprotID}`)
        ])
          .then(([response]) => {
            console.log(`got expression distribution and expression of ${uniprotID}`);
            console.log(response.data.result);
            console.log(response.data.results);
            setData2(response.data.result);
            setData(response.data.results);
          })
          .catch(error => {
            console.error(`there was an error communicating to the API: ${error}`);
          })
          .finally(() => {
            setLoading(false);
          });
    
        return () => {
          source.cancel();
        };
      }, [uniprotID]);

    useEffect(() => {
    if (!svgRef.current || loading) return;
   
    const processedData = data.filter((d) => d !== null).filter( d => d !== null && d !== 0).map(d => Math.log10(d));
    console.log(processedData);
    //const width = containerRef.current.clientWidth;
    //const height = containerRef.current.clientHeight;
    const width = Math.max(700,Math.min(containerRef.current.clientWidth,800));
    const height = Math.max(350,Math.min(containerRef.current.clientHeight,400));
    const margin = { top: 90, right: 20, bottom: 30, left: 80 };
    const padding = { top: 10, right: 50, bottom: 100, left: 20 };
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();
    svg.append('rect')
      .attr('x',0)
      .attr('y',0)
      .attr('width',width)
      .attr('height',height)
      .attr('fill','white');

    console.log('extent of log10iBAQ is..');
    console.log(d3.extent(processedData));
    
    const x = d3.scaleLinear()
      .domain(d3.extent(processedData))
      .range([margin.left+padding.left, width - margin.right-padding.right]);

    const kernelEpanechnikov = (k) => {
        return (v) => {
          return Math.abs(v /= k) <= 1 ? 0.75 * (1 - v * v) / k : 0;
        };
      };
    
    function kernelDensityEstimator(kernel, X) {
        return function(V) {
          return X.map(function(x) {
            return [x, d3.mean(V, function(v) { return kernel(x - v); })];
          });
        };
      }

    // Compute kernel density estimation
    
    let kernel = kernelEpanechnikov(0.3);
    const density = kernelDensityEstimator(kernel, x.ticks(100))(processedData);
    console.log('calculated density is..');
    console.log(density);
    
    const y = d3.scaleLinear()
    .domain([0, d3.max(density, (d) => density.find((p) => p[0] === d[0])[1])])
    .range([height - margin.bottom -padding.bottom, margin.top+padding.top])
    .nice();
  
  const area = d3.area()
    .curve(d3.curveBasis)
    .x((d) => x(d[0]))
    .y0(y(0))
    .y1((d) => y(density.find((p) => p[0] === d[0])[1]));
  
  svg.append('linearGradient')
    .attr('id', 'gradient')
    .selectAll('stop')
    .data([
      { offset: '0%', color: '#0000FF' },
      { offset: '25%', color: '#CCCCFF' },
      { offset: '50%', color: 'white' },
      { offset: '75%', color: '#FFCCCC' },
      { offset: '100%', color: '#FF0000' }
    ])
    .enter().append('stop')
    .attr('offset', (d) => d.offset)
    .attr('stop-color', (d) => d.color);
  
  svg.append('path')
    .datum(density)
    .attr('fill', 'url(#gradient)')
    .attr('opacity', 0.7)
    .attr('d', area);

    const line = d3.line()
  .curve(d3.curveBasis)
  .x((d) => x(d[0]))
  .y((d) => y(density.find((p) => p[0] === d[0])[1]));

  svg.append('path')
  .datum(density)
  .attr('fill', 'none')
  .attr('stroke', 'black')
  .attr('stroke-width', 1)
  .attr('d', line);

    const xAxis = (g) => g.attr('transform', `translate(0,${height - margin.bottom-padding.bottom})`)
      .call(d3.axisBottom(x).ticks(width / 80))
        .attr('font-size', '18px') // set the font size here
        .attr('font-family', 'Arial') // set the font family here
      .call((g) => g.append('text')
        .attr('x', width - margin.right)
        .attr('y', margin.bottom+10)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'end')
        .attr('font-size', '18px') // set the font size here
        .attr('font-family', 'Arial') // set the font family here
        .text('Log10(iBAQ)'))
        ;

    const yAxis = (g) => g.attr('transform', `translate(${margin.left+padding.left},0)`)
      .call(d3.axisLeft(y).ticks(null, 's'))
        .attr('font-size', '0px') // set the font size here
        .attr('font-family', 'Arial') // set the font family here
      .call((g) => g.append('text')
        .attr('x', -margin.left)
        .attr('y', 2+margin.top)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .attr('font-size', '18px') // set the font size here
        .attr('font-family', 'Arial') // set the font family here
        .text('Density'))

        svg.append('g').call(xAxis);
        svg.append('g').call(yAxis);

  // Compute position of arrow based on data2 value

if( data2.length == 0 ){
      // Add description to the SVG
      const tempText=svg.append('text')
      .attr('font-size', '24px')
      .attr('font-family', 'Arial') // set the font family here
      .text(`${genename} was not detected in the LC/MS`)

      const tempTextWidth=tempText.node().getComputedTextLength();
      tempText.remove();
      
      svg.append('rect')
      .attr('x',width/2-tempTextWidth/2-20)
      .attr('y',height/2-30)
      .attr('width',tempTextWidth+40)
      .attr('height',40)
      .attr('fill',"white")
      .attr('stroke', 'black')
      .attr('stroke-width', 1);

      svg.append('text')
      .attr('x', width / 2)
      .attr('y', height/2)
      .attr('text-anchor', 'middle')
      .attr('font-size', '24px')
      .attr('font-family', 'Arial') // set the font family here
      .text(`${genename} was not detected in the LC/MS`)

} else {
console.log('getting arrowposition');
console.log(data2.length);
console.log(Math.log10(data2));

const arrowPosition = data2.length > 0 ? data2[0] : 0;
const arrowX = x(Math.log10(arrowPosition));
 
  const bisect = d3.bisector((d) => d[0]).left;
const iBAQValue = Math.log10(arrowPosition);

// Find the index of the density value closest to the iBAQ value
const index = bisect(density, iBAQValue);
let densityValue=0;
if (index === 0) {
  // If iBAQ value is smaller than the first density value, use the first two values to interpolate
  const x0 = density[index][0];
  const y0 = density[index][1];
  const x1 = density[index + 1][0];
  const y1 = density[index + 1][1];
  const t = (iBAQValue - x0) / (x1 - x0);
densityValue = y0 + (y1 - y0) * t;
} else if (index === density.length) {
  // If iBAQ value is larger than the last density value, use the last two values to interpolate
  const x0 = density[index - 2][0];
  const y0 = density[index - 2][1];
  const x1 = density[index - 1][0];
  const y1 = density[index - 1][1];
  const t = (iBAQValue - x0) / (x1 - x0);
  densityValue = y0 + (y1 - y0) * t;
} else {
  // If iBAQ value is between two density values, interpolate between them
  const x0 = density[index - 1][0];
  const y0 = density[index - 1][1];
  const x1 = density[index][0];
  const y1 = density[index][1];
  const t = (iBAQValue - x0) / (x1 - x0);
  densityValue = y0 + (y1 - y0) * t;
}
  const arrowY = y(densityValue);
  console.log(arrowY);

  // Draw arrow
  const arrow = svg.append("g").attr("class", "arrow");
  arrow
    .append("path")
    .attr("d", "M 0 -5 L 10 0 L 0 5 L 0 -5")
    .attr("fill", "black")
    .attr("transform", `translate(${arrowX},${arrowY-10}) rotate(90)`)
    .attr("opacity", 1);

  const tempArrowText = arrow.append("text")
      .attr("text-anchor", "middle") // center the text horizontally
      .attr("x", arrowX)
      .attr("y", arrowY -15)
      .attr("fill", "black")
      .attr("font-size", "12px")
    .text(`${genename}: ${Math.log10(arrowPosition).toFixed(2)}`)
      .attr("background", "white")
      .attr('font-size', '22px') // set the font size here
      .attr('font-family', 'Arial'); // set the font family here
  
  const arrowTextWidth = tempArrowText.node()
    .getComputedTextLength();

  arrow
    .append("rect")
    .attr("x", arrowX-arrowTextWidth/2-10)
    .attr("y", arrowY-35)
    .attr("width",arrowTextWidth+20)
    .attr("height","25px")
    .style("stroke", "black")
    .style("stroke-width", "1px")
    .attr("fill","white")
    .style("z-index", 9);

  arrow.append("text")
    .attr("text-anchor", "middle") // center the text horizontally
    .attr("x", arrowX)
    .attr("y", arrowY -15)
    .attr("fill", "black")
  .text(`${genename}: ${Math.log10(arrowPosition).toFixed(2)}`)
    .attr("background", "white")
    .attr('font-size', '22px') // set the font size here
    .attr('font-family', 'Arial'); // set the font family here

    const percentile = d3.format('.1%')(1-d3.bisectRight(processedData.sort(d3.ascending), Math.log10(data2[0])) / processedData.length);

      // Add description to the SVG
    svg.append('text')
    .attr('x', width / 2)
    .attr('y', height-margin.bottom-padding.bottom+80)
    .attr('text-anchor', 'middle')
    .attr('font-size', '24px')
    .attr('font-family', 'Arial') // set the font family here
    .text(`${genename} has the abundance of top ${percentile} of all measured proteins.`);

    }

      // Add a title to the plot
  svg.append('text')
  .attr('x', width / 2)
  .attr('y', margin.top / 2)
  .attr('text-anchor', 'middle')
  .attr('font-size', '24px')
  .attr('font-family', 'Arial') // set the font family here
  .text(`log10(iBAQ)* of ${genename} measured in HEK293-TREx`);

    svg.append('text')
    .attr('x', width / 2)
    .attr('y', height-margin.bottom-padding.bottom+120)
    .attr('text-anchor', 'middle')
    .attr('font-size', '24px')
    .attr('font-family', 'Arial') // set the font family here
    .text(`*log10(iBAQ) is a label-free protein abundance measured via LC/MS`);

    const saveOptions = [
      { format: "svg", label: "Save as SVG" },
      { format: "jpeg", label: "Save as JPEG" },
      { format: "png", label: "Save as PNG" },
      { format: "pdf", label: "Save as PDF" }
    ];

    saveButton(btncontainerRef, saveOptions, svgRef, width, height, `HEKTREX_iBAQ_${genename}`);

    }, [data,data2, loading]);

    if (loading) {
    return <div>Loading...</div>;
    }
    
    return (
    <div className="chart-container ibaq" ref={containerRef}>
      <div className='savebtn_container' ref={btncontainerRef}></div>
      <svg ref={svgRef}  ></svg>
    </div>
    );
    }
    
    export default IBAQ;
        

   
