/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import './InteractionDetails.css';
import MoimGeneral from './MoimGeneral'
import MoimStructure from './MoimStructure'
import DynamicTitle from './DynamicTitle';
import PubMedSearch from './PubMedSearch';

function InteractionDetails() {
  
  const navigate = useNavigate();
  
  const [data, setData] = useState([]);
  
  const [length, setLength] = useState([]);
  
  const { fileName } = useParams();
  
  const [loading, setLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState('moim_general');

  const source = axios.CancelToken.source();
  
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  
  useEffect(() => {
    const debouncedQuery = debounce(() => {

    setLoading(true);
    const queries=[
      axios.get(`https://spatiomics.org/api/search?search=${fileName}&filter=moim`,),
    ];

    Promise.all(queries)
          .then(([response]) => {

            setData(response.data);
            setLength(response.length);
          
          })
          .catch(error => {

            console.error(`there was an error communicating to the API: ${error}`);

          })
          .finally(() => {

            setLoading(false);

          });
        }, 100);

        debouncedQuery();
        return () => {
          source.cancel();
        };
      }, [fileName]);

        if (loading) {
        return <div>
            Loading...</div>;
        }
        else if (length>1){
          console.log('moim_data');
          console.log(data);
          navigate('/searchresult', { state: { results: data } });

        } else if (length==0){
          console.log('moim_data');
          console.log(data);
          navigate('/searchresult', { state: { results: "notfound" } });

        }
        else  if (!data) {
        return <div>
            Interaction not found.</div>;
        } else {

          console.log('moim_data');
          console.log(data);

        return (
            <>
            <div className="App">
        <div className="App-header">
          <DynamicTitle />
        </div>

        </div>
        
        <SearchBar />
        
        <div className="title">
            {data[0].ProteinA_GeneName + '-' + data[0].ProteinB_GeneName}
        </div>
        <div className="protein-details">
        <div className="tabs">
        <button
          className={selectedTab === 'general' ? 'active' : 'inactive'}
          onClick={() => handleTabClick('moim_general')}
        >
          General
        </button>
        
        <button
          className={selectedTab === 'structure' ? 'active' : 'inactive'}
          onClick={() => {
            handleTabClick('moim_structure');
          }}
        >
          Structure
        </button>

        <button
          className={selectedTab === 'moim_pubmed' ? 'active' : 'inactive'}
          onClick={() => {
            handleTabClick('moim_pubmed');
          }}
        >
          Literature
        </button>
        
      </div>

      {selectedTab === 'moim_general' && (
        <div className="content general-properties">
          <MoimGeneral
          geneNameA = {data[0].ProteinA_GeneName}
          proteinNameA = {data[0].ProteinA_ProteinName}
          uniProtIDA = {data[0].ProteinA_UniProtID}
          geneNameB = {data[0].ProteinB_GeneName}
          proteinNameB = {data[0].ProteinB_ProteinName}
          uniProtIDB = {data[0].ProteinB_UniProtID}
          pearson = {data[0].pearsonCorrelation}
          maxScore  = {data[0].maxAfmmScore}
          fileName = {data[0].fileName}
          lengthA = {data[0].lengthA}
          lengthB = {data[0].lengthB}
          />
        </div>
      )}

      {selectedTab === 'moim_structure' && (
        <div className="content moim-structure">
          <MoimStructure
          fileName = {data[0].fileName}
          geneNameA = {data[0].ProteinA_GeneName}
          geneNameB = {data[0].ProteinB_GeneName}
          />
        </div>
      )}

      {selectedTab === 'moim_pubmed' &&(
        <div className="content moim_pubmed">
        <PubMedSearch 
        study = 'moim' 
        geneNameA = {data[0].ProteinA_GeneName}
        geneNameB = {data[0].ProteinB_GeneName}/>
      </div>
      )}


      </div>
        </>
        );
        }
    }

export default InteractionDetails;
