import React, { useState, useEffect } from 'react';
import axios from 'axios';
import XMLParser from 'react-xml-parser';

export default function PubMedSearch ({ genename, study, geneNameA, geneNameB }){

  const eutilsKey = '99a2897d29bd3407e46fdd65ec0a74047408' ;
  
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const source = axios.CancelToken.source();
  
  useEffect(() => {

  setLoading(true); 
  
  let queryArr= { querytext : [], queryurl : [], queryurl2 : []};

  const baseUrl = `/eutilsproxy/entrez/eutils/esearch.fcgi?db=pubmed&term={term}&api_key=${eutilsKey}`;

  const baseUrl2 = `https://pubmed.ncbi.nlm.nih.gov/?term={term}`;

  function insertArrQueries(genename, keywords){

    keywords.unshift(genename)

    queryArr.querytext.push(keywords.join(' + '));
    queryArr.queryurl.push(baseUrl.replace('{term}',`${keywords.join('+AND+')}`)); 
    queryArr.queryurl2.push(baseUrl2.replace('{term}',`${keywords.join('+')}`)); 

    return 0;
  }

  if( study === 'mitoatlas') {
    const queryWords = [
    [],  
    ['mitochondria'],
    ['mitochondrial'],
    ['drug'],
    ['cancer'],
    ['disease'],
    ['mitochondria','drug'],
    ['mitochondria','cancer'],
    ['mitochondrial','diesase']]

    queryWords.forEach(d=>{
      insertArrQueries(genename, d);
    })

  } else if( study === 'sarscov2') {
    const queryWords = [
    [],  
    ['SARS-CoV-2'],
    ['SARS-CoV'],
    ['virus'],
    ['viral'],
    ['membrane']]

    queryWords.forEach(d=>{
      insertArrQueries(genename, d);
    })

  } else if( study === 'moim') {
    const queryWords = [
    [],  
    ['interaction'],
    ['disulfide']]

    queryWords.forEach(d=>{
      insertArrQueries(geneNameA + '+' + geneNameB, d);
    })

  }


  let queries = [];
  
  queryArr.queryurl.forEach(url => {
    
  queries.push(axios.get(url));

  });

    Promise.all(queries)
      .then(responseArr => {
        let responseData = [];

        console.log("pubmed query results");

        responseArr.forEach( ( response ,i )=> {
          // create a new instance of the DOMParser object
          var xml = new XMLParser().parseFromString(response.data); 

          responseData.push( { querytext: queryArr.querytext[i],
            queryurl: queryArr.queryurl[i],
            queryurl2: queryArr.queryurl2[i],
            count:xml.children[0].children[0].value } );

        } )
        console.log('queryArr');
        console.log(queryArr);
        
        setData(responseData);
      
      })
      .catch(error => {
      
        console.error(`there was an error communicating to the API: ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      source.cancel();
    };
  }, [ genename , geneNameA, geneNameB, study ]);
  
  return (
    <div>
      <table style={{ borderCollapse: "collapse", width: "100%", maxWidth: "800px", margin: "0 auto" 
        ,fontFamily:'Arial'}}>
      <thead>
        <tr style={{ fontSize:20}}>
          <th style={{ backgroundColor: "#f2f2f2", fontWeight: "bold", textAlign: "left", border: "1px solid #ddd", padding: "8px" }}>PubMed Query</th>
          <th style={{ backgroundColor: "#f2f2f2", fontWeight: "bold", textAlign: "left", border: "1px solid #ddd", padding: "8px" }}>Count</th>
          <th style={{ backgroundColor: "#f2f2f2", fontWeight: "bold", textAlign: "left", border: "1px solid #ddd", padding: "8px" }}>Link</th>
        </tr>
      </thead>
      <tbody>
        { loading && <tr> Loading... </tr>}
        {data.map((d, i) => {
          return (
            <tr key={i}>
              <td style={{ textAlign: "center", border: "1px solid #ddd", padding: "8px" }}>{d.querytext}</td>
              <td style={{ textAlign: "center", border: "1px solid #ddd", padding: "8px" }}>{d.count}</td>
              <td style={{ textAlign: "center", border: "1px solid #ddd", padding: "8px" }}><a 
              href={d.queryurl2} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "#000" }}
              onMouseOver={(e) => e.target.style.textDecoration = "underline"}
              onMouseOut={(e) => e.target.style.textDecoration = "none"}>Link</a></td>
            </tr>
          );
        })}
      </tbody>
    </table>
    </div>
    )
}