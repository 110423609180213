import * as d3 from 'd3';
import  { ReactComponent as MitoSVG }from './svg/mito_sublocals_ver1.0.0.svg';
import getcolor from './getcolor';
import React, { useEffect , useRef } from 'react';
import saveButton from './saveButton';

export default function SubMitoSvg({style,localization_mitoatlas,genename}){
        const svgRef = useRef(null);
        const saveRef = useRef(null);
        const btncontainerRef = useRef(null);
        useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();
        console.log("creating submito graphic");
        
        // Define a color scale based on the 'sublocal' variable
        const labelPositions = [{'x':20,'y':20},
          {'x':5,'y':160},
          { 'x':5,'y':130},
          {'x':5,'y':100},
          {'x':5,'y':70},
          {'x':5,'y':40},
          {'x':5,'y':10}];
        const labelColors = [getcolor(style,'inconclusive','lighthex'),
        getcolor(style,'matrix','lighthex'),
        "url(#immGradient)",
        getcolor(style,'IMS','lighthex'),
        "url(#ommGradient)",
        getcolor(style,'OMM','lighthex'),
        getcolor(style,'non-mito','lighthex')];
        // Create a linear gradient
        const defs= svg.append("defs");
        defs.append("linearGradient")
        .attr("id", "immGradient")
        .attr("x1", '0%')
        .attr("y1", '100%')
        .attr("x2", '0%')
        .attr("y2", '0%')
        .selectAll("stop")
        .data([
          {offset: "0%", color: labelColors[1]},
          {offset: "40%", color: labelColors[1]},
          {offset: "60%", color: labelColors[3]},
          {offset: "100%", color: labelColors[3]}
        ])
        .enter().append("stop")
        .attr("offset", function(d) { return d.offset; })
        .attr("stop-color", function(d) { return d.color; });
        // Create a linear gradient
        defs.append("linearGradient")
        .attr("id", "ommGradient")
        .attr("x1", '0%')
        .attr("y1", '100%')
        .attr("x2", '0%')
        .attr("y2", '0%')
        .selectAll("stop")
        .data([
          {offset: "0%", color: labelColors[3]},
          {offset: "40%", color: labelColors[3]},
          {offset: "60%", color: labelColors[5]},
          {offset: "100%", color: labelColors[5]}
        ])
        .enter().append("stop")
        .attr("offset", function(d) { return d.offset; })
        .attr("stop-color", function(d) { return d.color; });

        const labelNames=['inconclusive','matrix','IMM','IMS','OMM','OMM periphery','non-mito'];
        
        //add localization tags to svg graphic(*except inconclusive)
        for (var i = 1; i <= 6; i++) {
          
          const labelContainer=svg.append('g');
          const testText=labelContainer
            .append("text")
            .attr('font-family','Arial')
            .attr('font-size',20)
            .text(labelNames[i]);
          const textbox=labelContainer.node().getBBox();
          labelContainer.attr("transform",`translate(${labelPositions[i].x},${labelPositions[i].y})`);
          labelContainer
            .append("rect")
            .attr("x",-3)
            .attr("y",-3)
            .attr("width",textbox.width+6)
            .attr("height",textbox.height+6)
            .attr("fill",labelColors[i])
            .attr("stroke","black")
            .attr("stroke-width",2);
          labelContainer
            .append("text")
            .attr('font-family','Arial')
            .attr('font-size',20)
            .attr("y",textbox.height-5)
            .text(labelNames[i]);
          testText.remove();
        }
        //add gene position in the submitochondria
        const positions = [{'x':240,'y':20},
        {'x':190,'y':200},
        {'x':190,'y':150},
        {'x':190,'y':120},
        {'x':240,'y':110},
        {'x':240,'y':100},
        {'x':280,'y':20}];
        console.log(localization_mitoatlas);
        console.log(localization_mitoatlas.substring(0, 6));
        //parse mitoaltas localization
        let sublocal=0;//0 equals inconclusive. 0 matrix 1 IMM 2 IMS 3 OMM-TM 4 OMM 5 nonmito
        if(localization_mitoatlas.substring(0, 6)==='matrix'){
          sublocal=1
        } else if(localization_mitoatlas.substring(0, 28)==='mitochondrial inner membrane'){
          sublocal=2
        } else if(localization_mitoatlas.substring(0, 3)==='IMS'){
          sublocal=3
        } else if(localization_mitoatlas.substring(0, 28)==='mitochondrial outer membrane'){
          sublocal=4
        } else if(localization_mitoatlas.substring(0, 3)==='OMM'){
          sublocal=5
        } else if(localization_mitoatlas.substring(0, 8)==='non-mito'){
          sublocal=6
        }
        const geneContainer=svg.append('g');
          const testText=geneContainer
            .append("text")
            .attr('font-family','Arial')
            .attr('font-size',20)
            .text(genename);
        const textbox=geneContainer.node().getBBox();
        geneContainer.attr("transform",`translate(${positions[sublocal].x},${positions[sublocal].y})`);
        geneContainer.append("rect")
          .attr('class', 'genename-label')
          .attr('x',-5)
          .attr('y',-3)
          .attr('rx',5)
          .attr('ry',5)
          .attr('width',textbox.width+10)
          .attr('height',textbox.height+6)
          .attr('stroke-width',2)
          .attr('stroke', 'black')
          .attr('fill', labelColors[sublocal]);
          geneContainer.append("text")
          .attr('stroke','black')
          .attr('x',0)
          .attr('y',textbox.height-5)
          .attr('font-family','Arial')
          .attr('font-size',20)
          .attr('class', 'genename-label')
          .text(genename); 
          testText.remove();

          const filename=`mitoatlas_sublocalization_of_${genename}`

          const saveOptions = [
            { format: "svg", label: "Save as SVG" },
            { format: "jpeg", label: "Save as JPEG" },
            { format: "png", label: "Save as PNG" },
            { format: "pdf", label: "Save as PDF" }
          ];
      
          saveButton(btncontainerRef, saveOptions, saveRef, 
            400,
            250,
             filename);

        }, [genename,localization_mitoatlas]);

          return (
          <div>
          <svg width="400" height="250" ref={saveRef}>
            <svg id="preMadeSvgViewBox" viewBox="80 40 400 250" width="400" height="250" >
            <MitoSVG/>
          </svg>
            <svg  ref={svgRef}>
          </svg>
          </svg>
          <div ref={btncontainerRef}>
          </div>
          </div>);
      }
