export default function SarsCoV2023Paper(){
    return (
        <div className="link">

        <a href="https://doi.org/10.1016/j.celrep.2023.112835" 
            target="_blank" 
            rel="noreferrer" 
            style={{
                stroke: 'black',
                fontSize: 18,
                fontFamily: 'Arial',
                marginLeft: 50,
                marginRight: 50,
            }}>

                Lee, Yun-Bin, et al. "Super-resolution proximity labeling reveals anti-viral protein network and its structural changes against SARS-CoV-2 viral proteins." <i>Cell Reports</i> 42.8 (2023). Related to Data S1 and Data S2.
        
        </a>

        </div>
        
    );
}

