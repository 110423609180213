/* eslint-disable no-restricted-globals */

import React, { useState, useEffect } from 'react';

import DynamicTitle from './DynamicTitle';

import SearchBar from './SearchBar';

import axios from 'axios';

import getcolor from './getcolor';

import Version from './Version' ;

import Dev from './Dev';

export default function SarsCoV2Main() {

    const currentHost = window.location.hostname;

    let subDomain = '';

    if (currentHost.split('.').length >= 2 ) {

      subDomain = currentHost.split('.')[0];

    }
    //set the title of the page to MitoAtlas
    useEffect(() => {

      document.title = 'MitoAtlas';

    }, []);

    return <>

    <div className="App">

        <div className="App-header" 
        style={{height: '100px'}}>

        <DynamicTitle />

        </div>

        <SearchBar />

        <div className="subtitle">Contributing Groups</div>

        <div className="link">

            <a href="https://sites.google.com/view/rheesnu" target="_blank" rel="noreferrer" >Rhee lab</a>
        
        </div>

        <div className="link">

            <a href="https://biosci.snu.ac.kr/proteomics/" target="_blank" rel="noreferrer" >Systems Mass Spectrometry Lab</a>
        
        </div>

        <div className="subtitle">Publications</div>

        <div className="link">

            <a href="#" className="disabled">MitoAtlas(Manuscript in preparation.)</a>

        </div>
        
        <Dev />

        <Version />

    </div>

    </>
}