import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

export default function MitoAtlasTitle() {
    
      const svgRef=useRef(null);

      const svgContainerRef = useRef(null);

      const svgHeight = useState(100)[0];

      const svgWidth = useState(100)[0];

      useEffect(()=>{

        const svg = d3.select(svgRef.current);

        svg.selectAll('*').remove();

        svg.append('rect')
            .attr('x',0)
            .attr('y',0)
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .attr('fill','white');

        //add oval shape as the boundary of mitochondrion to svg.
        const elipseMargin = {x: 7, y: 7};

        const elipseOuter = svg.append('ellipse')
            .attr('cx', svgWidth/2 )
            .attr('cy', svgHeight/2 )
            .attr('rx', svgWidth/2 - elipseMargin.x + 10)
            .attr('ry', svgHeight/2 * 0.85 - elipseMargin.y + 10)
            .attr('fill', 'url(#grad1)')

        const elipseInner = svg.append('ellipse')
            .attr('cx', svgWidth/2)
            .attr('cy', svgHeight/2)
            .attr('rx', svgWidth/2 - elipseMargin.x)
            .attr('ry', svgHeight/2 * 0.85 - elipseMargin.y)
            .attr('fill', 'rgb(165, 246, 255)')
            .attr('stroke','black')
            .attr('stroke-width', 3);
        //add radial gradient to svg
        const radialGradient = svg.append('defs')
            .append('radialGradient')
            .attr('id','grad1')
            .attr('cx','50%')
            .attr('cy','50%')
            .attr('r','50%')
            .attr('fx','50%')
            .attr('fy','50%');

        radialGradient.append('stop')
            .attr('offset','0%')
            .attr('style','stop-color:rgb(235,235,105);stop-opacity:1');
        
        radialGradient.append('stop')  
            .attr('offset','75%')
            .attr('style','stop-color:rgb(235,235,105);stop-opacity:1');

        radialGradient.append('stop')  
            .attr('offset','100%')
            .attr('style','stop-color:rgb(255,255,255);stop-opacity:1');

        //add a sqiggly shape to svg to represent the cristae

        const sqigglyMargin = { x: 20, y: 20 };

        const sqigglyWidth = svgWidth - sqigglyMargin.x * 2;

        const sqigglyPath = d3.path();

        sqigglyPath.moveTo(sqigglyMargin.x, svgHeight * 0.65);
        //First turn
        sqigglyPath.bezierCurveTo(sqigglyMargin.x, sqigglyMargin.y * 2, 
                sqigglyMargin.x, sqigglyMargin.y * 1.6, 
                sqigglyMargin.x + sqigglyWidth * 0.1, sqigglyMargin.y * 1.5);

        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.2, sqigglyMargin.y * 1.4, 
            sqigglyMargin.x + sqigglyWidth * 0.2, sqigglyMargin.y * 1.4, 
            sqigglyMargin.x + sqigglyWidth * 0.2, svgHeight * 0.5);
        //Second turn
        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.2, svgHeight - sqigglyMargin.y * 1.8, 
            sqigglyMargin.x + sqigglyWidth * 0.2, svgHeight - sqigglyMargin.y * 1.8, 
            sqigglyMargin.x + sqigglyWidth * 0.3, svgHeight - sqigglyMargin.y * 1.7);

        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.4, svgHeight - sqigglyMargin.y * 1.7, 
            sqigglyMargin.x + sqigglyWidth * 0.4, svgHeight - sqigglyMargin.y * 1.7, 
            sqigglyMargin.x + sqigglyWidth * 0.4, svgHeight * 0.5);
        //Third turn
        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.4, sqigglyMargin.y * 1.25, 
            sqigglyMargin.x + sqigglyWidth * 0.4, sqigglyMargin.y * 1.25, 
            sqigglyMargin.x + sqigglyWidth * 0.5, sqigglyMargin.y * 1.2);

        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.6, sqigglyMargin.y * 1.25, 
            sqigglyMargin.x + sqigglyWidth * 0.6, sqigglyMargin.y * 1.25, 
            sqigglyMargin.x + sqigglyWidth * 0.6, svgHeight * 0.5);
        //Fourth turn
        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.6, svgHeight - sqigglyMargin.y * 1.3, 
            sqigglyMargin.x + sqigglyWidth * 0.6, svgHeight - sqigglyMargin.y * 1.3, 
            sqigglyMargin.x + sqigglyWidth * 0.7, svgHeight - sqigglyMargin.y * 1.3);

        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.8, svgHeight - sqigglyMargin.y * 1.4, 
            sqigglyMargin.x + sqigglyWidth * 0.8, svgHeight - sqigglyMargin.y * 1.4, 
            sqigglyMargin.x + sqigglyWidth * 0.8, svgHeight * 0.5);

        //Final turn
        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 0.8, sqigglyMargin.y * 1.4, 
            sqigglyMargin.x + sqigglyWidth * 0.8, sqigglyMargin.y * 1.4, 
            sqigglyMargin.x + sqigglyWidth * 0.9, sqigglyMargin.y * 1.5);

        sqigglyPath.bezierCurveTo(sqigglyMargin.x + sqigglyWidth * 1, sqigglyMargin.y * 1.6, 
            sqigglyMargin.x + sqigglyWidth * 1, sqigglyMargin.y * 1.6, 
            sqigglyMargin.x + sqigglyWidth * 1, svgHeight * 0.65);

        
        //Sqiggly line for cristae membrane(rounded ends)
        svg.append('path')
            .attr('d', sqigglyPath)
            .attr('fill','none')
            .attr('stroke','black')
            .attr('stroke-width', 10)
            .attr('stroke-linecap', 'round');

        //A rect for alphabet A
        svg.append('circle')
            .attr('cx', svgWidth * 0.735)
            .attr('cy', svgHeight * 0.5 - 4)
            .attr('r', 3.5)
            .attr('fill', 'rgb(220,60,60)')
            .attr('stroke', 'black')
            .attr('stroke-width', 3);

        //Sqiggly line for cristae matrix, slightly shorter than the membrane(help finish)
        svg.append('path')
            .attr('d', sqigglyPath)
            .attr('fill','none')
            .attr('stroke','rgb(120,0,0)')
            .attr('stroke-width', 4)
            .attr('stroke-linecap', 'round');
        
        svg.append('path')
            .attr('d', sqigglyPath)
            .attr('fill','none')
            .attr('stroke','rgb(220,60,60)')
            .attr('stroke-width', 4)
            .attr('stroke-dasharray', '195 22')
            .attr('stroke-dashoffset', 19)
            .attr('stroke-linecap', 'round');

            
      }
      ,[svgRef])
      

      return (
        <div className="titlecontainer" 
            ref={svgContainerRef}
            style={{
                width: svgWidth,
                height: svgHeight,
            }}>
            <svg ref={svgRef}>
            </svg>
        </div>
      );
  }
  
 ; 