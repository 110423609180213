

/* eslint-disable no-restricted-globals */
import React, { useRef, useState, useEffect } from 'react';
import './SeqExplorer.css';
import domtoimage from 'dom-to-image';

function SeqExplorer({study, sequence, genename}) {
  const aminoAcidSequence = sequence;
  const aminoAcidSequenceElement = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [fontSize, setFontSize] = useState(16);
  const [aminoAcidWidth, setAminoAcidWidth] = useState(10);
  const btncontainerRef = useRef(null);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setLastX(event.clientX);
    aminoAcidSequenceElement.current.style.cursor = 'grabbing';
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    aminoAcidSequenceElement.current.style.cursor = 'grab';
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const deltaX = event.clientX - lastX;
      setLastX(event.clientX);
      aminoAcidSequenceElement.current.scrollLeft -= deltaX;
    }
  };

  const handleWheel = (event) => {
    event.preventDefault();
    const isTouch = event.type === 'touchmove';
    const delta = isTouch ? event.touches[0].clientY - event.touches[0].clientY : event.deltaY;
    const zoomSpeed = 0.3;
    const zoomFactor = 1 + zoomSpeed * Math.sign(delta);
    const newFontSize = fontSize * Math.pow(zoomFactor, 0.5);
    if (newFontSize >= 3 && newFontSize <= 32) {
      setFontSize((prevFontSize) => prevFontSize * Math.pow(zoomFactor, 0.5));
      setAminoAcidWidth((prevWidth) => prevWidth * zoomFactor);
    }
  };

  const handleSaveAsJPG = () => {
    const aminoAcidSequenceContainer = aminoAcidSequenceElement.current;
    const initialScrollLeft = aminoAcidSequenceContainer.scrollLeft;
    const containerWidth = aminoAcidSequenceContainer.scrollWidth;
    const containerHeight = aminoAcidSequenceContainer.scrollHeight;
    const visibleWidth = aminoAcidSequenceContainer.clientWidth;
    const visibleHeight = aminoAcidSequenceContainer.clientHeight;
    aminoAcidSequenceContainer.scrollLeft = containerWidth - visibleWidth;
    domtoimage.toJpeg(aminoAcidSequenceContainer, { quality: 0.95, width: containerWidth, height: containerHeight })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `${study}_SequenceExplorer_${genename}.jpg`;
        link.href = dataUrl;
        link.click();
        aminoAcidSequenceContainer.scrollLeft = initialScrollLeft;
      });
  };

  useEffect(() => {
    if (aminoAcidSequenceElement.current) {
      aminoAcidSequenceElement.current.addEventListener('wheel', handleWheel, { passive: false });
      aminoAcidSequenceElement.current.addEventListener('touchmove', handleWheel, { passive: false });
    }
    return () => {
      if (aminoAcidSequenceElement.current) {
        aminoAcidSequenceElement.current.removeEventListener('wheel', handleWheel, { passive: false });
        aminoAcidSequenceElement.current.removeEventListener('touchmove', handleWheel, { passive: false });
      }
    };
  }, [aminoAcidSequenceElement, handleWheel]);
  const handleAminoAcidClick = (event) => {
    const selectedAminoAcidElement = event.target.closest('.amino-acid');
    const aminoAcidSequenceElement = document.querySelector('.amino-acid-sequence');
  
    if (selectedAminoAcidElement) {
      const isAlreadySelected = selectedAminoAcidElement.classList.contains('selected');
      aminoAcidSequenceElement.querySelectorAll('.amino-acid').forEach((aminoAcidElement) => {
        aminoAcidElement.classList.remove('selected');
      });
      if (!isAlreadySelected) {
        selectedAminoAcidElement.classList.add('selected');
      }
  
      // Add event listener to document to handle click outside amino acid element
      const handleDocumentClick = (event) => {
        if (!event.target.closest('.amino-acid-sequence')) {
          aminoAcidSequenceElement.querySelectorAll('.amino-acid').forEach((aminoAcidElement) => {
            aminoAcidElement.classList.remove('selected');
          });
          document.removeEventListener('click', handleDocumentClick);
        }
      };
      document.addEventListener('click', handleDocumentClick);
    }
  };
  

  return (
    <>
    <div
      ref={aminoAcidSequenceElement}
      className="amino-acid-sequence"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      style={{ fontSize: `${fontSize}px`, 
      alignItems: 'flex-start' ,
      flexDirection: 'column',
      transform: 'rotateX(180deg)'
        }}
    >
    <div className="amino-acid-sequence-container"
        style={{ fontSize: `${fontSize}px`,
        display: 'flex', 
        alignItems: 'flex-end',
        flexDirection: 'row',
        transform: 'rotateX(180deg)' }}>
      <div className="amino-acid n-term"
      style={{ fontSize: `20px`,
      width: `90px`, 
      height: `20px`,
      borderRight: `${fontSize < 15 ? 'none' : '1px solid black'}`}}
      >N-term</div>
      {aminoAcidSequence.split('').map((aminoAcid, index) => (
        <div
        key={index}
        className="amino-acid"
        style={{
        width: `${aminoAcidWidth*2}px`,
        height: `20px`,
        fontSize: `${fontSize}px`,
        borderRight: `${fontSize < 15 ? 'none' : '1px solid black'}`,
        color: `${fontSize < 15 ? 'transparent' : 'black'}`,
        }}
        onClick={handleAminoAcidClick}
        >
        {aminoAcid}
        </div>
        ))}
        <div className="amino-acid c-term"
        style={{ fontSize: `20px`,
        width: `90px`,
        height: `20px`,
        borderRight: '0px solid black' }}
        >C-term</div>
</div>
<div
  className="amino-acid-index-container"
  style={{
    fontSize: `14px`,
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    transform: 'rotateX(180deg)',
  }}
>
  <div
    className="amino-acid n-term"
    style={{
      fontSize: `20px`,
      width: `90px`,
      height: `20px`,
      background: 'transparent',
      borderRight: `none`
    }}
  ></div>
  {aminoAcidSequence.split('').map((aminoAcid, index) => {
    const totalIndices = aminoAcidSequence.length;
    const skipInterval = Math.round(Math.floor(totalIndices / (4 * fontSize)) / 5) * 5;
    const shouldSkip =
      index > 0 &&
      index < totalIndices - 1 &&
      ((index + 1) % skipInterval!== 0 || (totalIndices -(index + 1 )) / skipInterval < 1);
    return (
      <div
        key={index}
        className="amino-acid-index"
        style={{
          height: `20px`,
          width: `${aminoAcidWidth * 2}px`,
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          color: shouldSkip ? 'transparent' : 'black',
          borderRight: `${fontSize < 15 ? 'none' : '1px solid white'}`
        }}
      >
        {shouldSkip ? '' : index + 1}
      </div>
    );
  })}
  <div
    className="amino-acid c-term"
    style={{
      fontSize: `20px`,
      background: 'transparent',
      width: `90px`,
      height: `20px`,
      borderRight: '0px solid black',
    }}
  ></div>
</div>
</div>
<div ref={btncontainerRef} className="btn-container">
  <button onClick={handleSaveAsJPG}
  style={{
    zIndex:1,
    borderRadius:0,
    width:'120px',
    height:'30px',
    fontSize:'20px',
    fontFamily:'Arial',
    textAlign:'center'
  }}
  >Save(JPG)</button>
</div>
</>
);
}

export default SeqExplorer;
