import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

export default function SarsCov2Title() {
      const svgRef=useRef(null);
      const svgContainerRef=useRef(null);

      useEffect(()=>{

        const svg = d3.select(svgRef.current);
      svg.selectAll('*').remove();

      const svgWidth = 100; // Width of the SVG element
      const svgHeight = 100; // Height of the SVG element

      svg.append('rect')
        .attr('x',0)
        .attr('y',0)
        .attr('width',svgWidth)
        .attr('height',svgHeight)
        .attr('fill','white');

        const defs = svg.append('defs');
        const texture = defs
            .append('pattern')
            .attr('id', 'dnaTexture')
            .attr('width', 10)
            .attr('height', 30)
            .attr('patternUnits', 'userSpaceOnUse');

            
        texture
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 10)
            .attr('height', 30)
            .attr('fill', '#a1a1a1');

        texture
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 10)
            .attr('height', 10)
            .attr('fill', '#4169E1');

        texture
            .append('rect')
            .attr('x', 0)
            .attr('y', 10)
            .attr('width', 10)
            .attr('height', 8)
            .attr('fill', '#2c92e6');

        texture
            .append('rect')
            .attr('x', 0)
            .attr('y', 20)
            .attr('width', 10)
            .attr('height', 10)
            .attr('fill', '#4169E1');
        
        texture
            .append('rect')
            .attr('x', 0)
            .attr('y', 20)
            .attr('width', 10)
            .attr('height', 6)
            .attr('fill', '#1ec78c');

        const circleRadius = 30; // Radius of the circle
        const sCurveAmplitude = 0; // Amplitude of the 'S' curve
        const sCurveFrequency = 5; // Frequency of the 'S' curve
        const controlPointRandomness = 5; // Maximum randomness applied to control points

    for (let i = 0; i < 6; i++) {
        const angle = (Math.PI / 3) * i; // Angle for each receptor

        // Calculate the position of each receptor

        const sine = Math.sin(angle);
        const cosine = Math.cos(angle);

        const receptorX = svgWidth / 2 +  ( circleRadius - 3 ) * sine;
        const receptorY = svgHeight / 2  - ( circleRadius - 3 ) * cosine;

        // Create the receptor path data
        const receptorPathData = `M${receptorX - 10  * cosine },${receptorY - 10  * sine} 
            C${receptorX - 5 * cosine + 2.5 * sine},${receptorY - 2.5  * cosine - 5 * sine} 
            ${receptorX - 5 * cosine + 7.5 * sine },${receptorY - 7.5  * cosine - 5 * sine} 
            ${receptorX - 10  * cosine + 10 * sine },${receptorY - 10  * sine - 10  * cosine}
            
            C${receptorX + 20 * sine - 30 * cosine},${receptorY - 30 * sine - 20  * cosine} 
            ${receptorX + 20 * sine + 30 * cosine},${receptorY + 30 * sine - 20  * cosine}
            ${receptorX + 10  * cosine + 10 * sine },${receptorY + 10  * sine - 10  * cosine}
            
            C${receptorX + 5 * cosine + 7.5 * sine},${receptorY - 7.5  * cosine + 5 * sine} 
            ${receptorX + 5 * cosine + 2.5 * sine },${receptorY - 2.5  * cosine + 5 * sine} 
            ${receptorX + 10  * cosine},${receptorY + 10  * sine}`;
            
            //C${receptorX + 20 * sine},${receptorY + 20 * cosine} 
            //${receptorX + 20 * sine},${receptorY + 20 * cosine } 
            //${receptorX - 10  * cosine},${receptorY + 10  * sine}`;

        // Append a path element for each receptor
        svg
            .append("path")
            .attr("d", receptorPathData)
            .attr("stroke", "black")
            .attr("stroke-width", 5)
            .attr("fill", "#6be8ae");
        }

        const circle = svg.append('circle')
        .attr('cx', 50)
        .attr('cy', 50)
        .attr('r', circleRadius)
        .attr('stroke', 'black')
        .attr('stroke-width', 5)
        .attr('fill', '#7ee6a2');

        const innerCircle = svg.append('circle')
        .attr('cx', 50)
        .attr('cy', 50)
        .attr('r', circleRadius-4.6)
        .attr('stroke', '#38ad2d')
        .attr('stroke-width', 5)
        .style("stroke-dasharray", "7,3") // Set the stroke-dasharray to create a dashed line
        .attr('fill', 'none');
        
    // Create a path for the 'S' shape
    const sPath = svg
        .append("path")
        .attr("stroke", 'url(#dnaTexture)')
        //.attr("stroke", "#4169E1")
        //.attr('fill', 'url(#dnaTexture)');
        .attr("stroke-width", 10)
        .attr('fill','none');
        
        // Animation function to update the path data
        let previousRotation = 0; // Store the previous rotation angle

        function animate() {
            const t = Date.now() * sCurveFrequency; // Time parameter for animating the 'S' curve
          
            // Generate random offsets for the control points
            const randomOffset1X = Math.random() * controlPointRandomness * 2 - controlPointRandomness;
            const randomOffset1Y = Math.random() * controlPointRandomness * 2 - controlPointRandomness;
            const randomOffset2X = Math.random() * controlPointRandomness * 2 - controlPointRandomness;
            const randomOffset2Y = Math.random() * controlPointRandomness * 2 - controlPointRandomness;

            // Calculate the control points for the 'S' shape
            const controlPoint1X = svgWidth / 2 - 3.5 * ( circleRadius - 10) / 1.414  + randomOffset1X;
            const controlPoint1Y = svgHeight / 2 - ( circleRadius - 10) /  1.414 + sCurveAmplitude * Math.sin(t) + randomOffset1Y;
            const controlPoint2X = svgWidth / 2 + 3.5 * ( circleRadius - 10) / 1.414  + randomOffset2X;
            const controlPoint2Y = svgHeight / 2 + ( circleRadius - 10) /  1.414- sCurveAmplitude * Math.sin(t) + randomOffset2Y;


            // Generate path data for the 'S' shape
            const pathData = `M ${svgWidth / 2 + ( circleRadius - 10) / 1.414 + Math.random() * controlPointRandomness * 1 - controlPointRandomness * 0.5 } ${svgHeight / 2 - ( circleRadius - 10) /  1.414 + Math.random() * controlPointRandomness * 1 - controlPointRandomness * 0.5 }
                                C ${controlPoint1X} ${controlPoint1Y}
                                ${controlPoint2X} ${controlPoint2Y}
                                ${svgWidth / 2 - ( circleRadius - 10) / 1.414 + Math.random() * controlPointRandomness * 1 - controlPointRandomness * 0.5 } ${svgHeight / 2 + ( circleRadius - 10)  /  1.414 + Math.random() * controlPointRandomness * 1 - controlPointRandomness * 0.5 }`;

            const duration = Math.random() * 200 + 400; // Random duration between 1000ms and 5000ms
            
            const rotationRange = 2.5*duration/60; // Range of rotation change in degrees
            const minRotation = previousRotation - rotationRange;
            const maxRotation = previousRotation + rotationRange;
            const rotation = Math.random() * (maxRotation - minRotation) + minRotation + duration/15; // Random rotation angle within the specified range

            const easingFunctions = [
                d3.easeLinear,
                d3.easeQuadIn,
                d3.easeQuadOut,
                d3.easeQuadInOut,
                d3.easeCubicIn,
                d3.easeCubicOut,
                d3.easeCubicInOut,
                d3.easeSinIn,
                d3.easeSinOut,
                d3.easeSinInOut
              ];
              const randomEasing = easingFunctions[Math.floor(Math.random() * easingFunctions.length)]; // Randomly select an easing function from the list
            

          // Update the path data with smooth transition
            sPath
            .transition()
            .duration(duration) // Transition duration in milliseconds
            .attr("d", pathData)
            .attr("transform", `rotate(${rotation} ${svgWidth / 2} ${svgHeight / 2})`) // Apply rotation around SVG center
            .ease(d3.easeLinear)
            .on("end", animate); // Restart the animation after each transition;



            previousRotation = rotation; // Store the current rotation for the next iteration

        }
        
        // Start the animation
        animate();

      }
      ,[svgRef])
      

      return (
        <div className="titlecontainer" 
            ref={svgContainerRef}
            style={{
                width: 100,
                height: 100,
            }}>
            <svg ref={svgRef}>
            </svg>
        </div>
      );
  }
  
 ; 