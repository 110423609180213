// using given TM anno and sites, give localization and score to it.
export default function evalTManno({sites,TMs}){
    //declare logit
    function logit(p) {
        return Math.log(p / (1 - p));
    }
    //declar denomiator logit 0.1
    const deNom=(logit(0.9)-logit(0.1));
    const logitPointZero=logit(0.1);

    let prevTMdirection = 1;
    let prevIndex=0;
    const labelNames = ['inconclusive','matrix','IMM','IMS','OMM','OMM periphery','non-mito'];
    //for default, 'lower' is the N terminus side
    const siteCounts = {
            lower:{inconclusive:0, matrix:0, IMS:0, OMM:0, 'non-mito':0},
            upper:{inconclusive:0, matrix:0, IMS:0, OMM:0, 'non-mito':0}
        };
    console.log(TMs);
    TMs.forEach((tm,i)=>{
    //if i==0, check for N term loop sites
    if(i==0){
        const Ntermsites = sites.filter(d => d.site < tm.start);
        Ntermsites.forEach(site=>{
            siteCounts.lower[site.Localization_mitoatlas]+=1;
        })
    } else {
    //if i!=0, check for previous loop
        const loopsites = sites.filter(d => d.site < tm.start && d.site > prevIndex);
        loopsites.forEach(site=>{
            if(prevTMdirection==1){
                siteCounts.lower[site.Localization_mitoatlas]+=1;
            } else {
                siteCounts.upper[site.Localization_mitoatlas]+=1;
            }
            
        })
    }

    //now check sites that are iside the TM domain
    //IMPORTANT: tm sites are considered "in between" and "blurry" so both upper and lower scores are added using a part of logit function
    const TMsites = sites.filter(d => d.site <= tm.end && d.site >= tm.start);
    TMsites.forEach(site=>{
        const calcScore = (logit(0.1+0.8*(site.site-tm.start)/(tm.end-tm.start))-logitPointZero)/deNom;
        if(prevTMdirection!=1){
            siteCounts.lower[site.Localization_mitoatlas] += calcScore;
            siteCounts.upper[site.Localization_mitoatlas] += 1 - calcScore;
        } else {
            siteCounts.upper[site.Localization_mitoatlas] += calcScore
            siteCounts.lower[site.Localization_mitoatlas] += 1 - calcScore;
        }
    })

    //if i==TMs.length, check for C term loop sites
    if(i==TMs.length-1){
        const Ctermsites = sites.filter(d => d.site > tm.end);
        Ctermsites.forEach(site=>{
            if(prevTMdirection!=1){
                siteCounts.lower[site.Localization_mitoatlas]+=1;
            } else {
                siteCounts.upper[site.Localization_mitoatlas]+=1;
            }
        })
    }

    //now flip the TM direction and toss to next TM
    prevTMdirection=-prevTMdirection;
    prevIndex=tm.end;

    })

    //now calculate pred scores for IMS and OMM and for each orientations.
    //return all scores for each orientations.
    //final score 
    let maxScore = 0;
    //contribution of each sites to the final score
    let contrib=[];
    //final prediction for which membrane the TM protein is
    let predLocal = 'inconclusive';

    let targetTMs=[{name:'IMM',score1:0,score2:0},{name:'OMM',score1:0,score2:0}];

    let lowersum = 0;

    for (let key in siteCounts.lower) {
        if (key !== 'inconclusive') {
            lowersum += siteCounts.lower[key];
        } 
    }

    let uppersum = 0;

    for (let key in siteCounts.upper) {
        if ( key !== 'inconclusive' ) {
            uppersum += siteCounts.upper[key] ;
        } 
    }

    console.log('lowersum');
    console.log(lowersum);
    console.log('uppersum');
    console.log(uppersum);

    let targetMem = 'none'

    targetTMs.forEach( target => {

        if(target.name=='IMM'){

            target.score1+=siteCounts.lower['matrix']*2+siteCounts.upper['IMS']*2-lowersum-uppersum;
            
            target.score2+=siteCounts.upper['matrix']*2+siteCounts.lower['IMS']*2-lowersum-uppersum;

            if(maxScore < target.score1 || maxScore < target.score2){
                maxScore = Math.max( target.score1 , target.score2 ) ;
                targetMem = 'IMM'
            }

        } else if (target.name=='OMM'){

            target.score1 += siteCounts.lower['IMS'] * 2 + siteCounts.upper['OMM'] * 2 - lowersum - uppersum + siteCounts.upper['non-mito']*0.5;

            target.score2 += siteCounts.upper['IMS'] * 2 + siteCounts.lower['OMM'] * 2 - lowersum - uppersum + siteCounts.lower['non-mito']*0.5;;

            if(maxScore < target.score1 || maxScore < target.score2){
                maxScore = Math.max( target.score1 , target.score2 ) ;
                targetMem = 'OMM'
            }
        }
    })

    if(maxScore > 0 ){

        const targetWithHighestScore = targetTMs.find((d) => d.score1 == maxScore || d.score2 == maxScore);

        return {score1: targetWithHighestScore.score1 , score2: targetWithHighestScore.score2, targetMem:targetMem , contrib:contrib};

    } else {
        
        return {score1: 0 , score2: 0, targetMem:'none' , contrib:contrib}

    }
    
}