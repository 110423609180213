/* eslint-disable no-restricted-globals */
import React from 'react';
import './GeneralProperties.css'

function GeneralProperties({ genename, proteinname, uniprotID , localization_mitoatlas , localization_uniprot}) {

  return(
    <table class="general-properties-table">
    <tbody class="tbody">
      <tr class="row">
        <td class="column"><strong>Organism:</strong></td>
        <td class="column"><em>Homo sapiens</em></td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Protein name, UniProt:</strong></td>
        <td class="column">{proteinname}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Gene name, UniProt:</strong></td>
        <td class="column">{genename}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>UniProt ID:</strong></td>
        <td class="column"><a href={`https://www.uniprot.org/uniprotkb/${uniprotID}/entry`}>{uniprotID}</a></td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Localization, MitoAtlas:</strong></td>
        <td class="column">{localization_mitoatlas}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Localization, Uniprot:</strong></td>
        <td class="column">{localization_uniprot}</td>
      </tr>
    </tbody>
  </table>
  );
}

export default GeneralProperties;
