/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './Heatmap.css';
import saveButton from './saveButton';

function Heatmap({style, study, enzyme, uniprotID, genename }) {
    const [data, setData] = useState([]);
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isdata, setIsdata] = useState(false);
    const svgRef = useRef(null);
    const source = axios.CancelToken.source();
    const containerRef = useRef(null);
    const btncontainerRef = useRef(null);
    console.log('using style');
    console.log(style);

    const siteFeatureForStudy = {'mitoatlas':'Localization_mitoatlas'
      ,'sarscov2':'sarscov2_interactome'}

    useEffect(() => {
        setLoading(true);
        console.log(`getting ${study} ${enzyme} data of ${uniprotID}`);
        const queries=[
          axios.get(`https://spatiomics.org/api/pldata?study=${study}&enzyme=${enzyme.toLowerCase()}&uid=${uniprotID}`),
          axios.get(`https://spatiomics.org/api/pldata?study=${study}&uid=${uniprotID}&sitefeature=${siteFeatureForStudy[study]}&enzyme=${enzyme.toLowerCase()}`)
        ];
        Promise.all(queries)
          .then(([response,response2]) => {
            setData(response.data);
            setSites(response2.data);
            if(response.data.result1[0].length>0){
              setIsdata(true);
            }
          })
          .catch(error => {
            console.error(`there was an error communicating to the API: ${error}`);
          })
          .finally(() => {
            setLoading(false);
          });
        return () => {
          source.cancel();
        };
      }, [uniprotID, enzyme, study]);

    useEffect(() => {
    if (!svgRef.current || loading || !isdata) return;
    // Extract the keys for the x-axis and y-axis
    
    console.log('sites');
    console.log(sites);
    console.log('data');
    console.log(data);


    const yKeys = [...new Set(data.result1[0].map(obj => obj.site_id))].sort((a, b) => {
      const siteIdA = parseInt(a.split('_')[1]);
      const siteIdB = parseInt(b.split('_')[1]);
    
      return siteIdA - siteIdB;
    });;
    // yKeys are the different site_ids from the data[0];

    let sortedGroups;
    let xKeys;

    if (study !== 'mitoatlas') {

      sortedGroups = data.result2[0].sort((a, b) => {
        const order = ['matrix', 'IMS', 'OMM'];
        return order.indexOf(a.baitloc) - order.indexOf(b.baitloc);
      });

      xKeys = [...new Set(sortedGroups.map(obj => obj.experiment))];

    } else {

      sortedGroups = data.result2[0].sort((a, b) => {
        const order = ['matrix', 'IMS', 'OMM', 'non-mito'];
        return order.indexOf(a.baitloc) - order.indexOf(b.baitloc);
      });
      //add {group: P_matrix}, P_IMS, P_OMM, P_non-mito to sortedGroups
      sortedGroups.push({experiment: 'P_matrix_1', group: 'P_matrix'}, {experiment: 'P_matrix_2', group: 'P_matrix'}, {experiment: 'P_matrix_3', group: 'P_matrix'},
        {experiment: 'P_IMS_1', group: 'P_IMS'}, {experiment: 'P_IMS_2', group: 'P_IMS'}, {experiment: 'P_IMS_3', group: 'P_IMS'},
        {experiment: 'P_OMM_1', group: 'P_OMM'}, {experiment: 'P_OMM_2', group: 'P_OMM'}, {experiment: 'P_OMM_3', group: 'P_OMM'},
        {experiment: 'P_non-mito_1', group: 'P_non-mito'}, {experiment: 'P_non-mito_2', group: 'P_non-mito'}, {experiment: 'P_non-mito_3', group: 'P_non-mito'});

      
      xKeys = [...new Set(sortedGroups.map(obj => obj.experiment))];
      
    }

    const groupKeys = [...new Set(sortedGroups.map(obj => obj.group))];

    console.log('sortedGroups');
    console.log(sortedGroups);
    console.log('groupKeys');
    console.log(groupKeys);
    console.log('xKeys');
    console.log(xKeys);

    // Set up margin, width, and height of heatmap
    let margin;
    let padding;

    if (study === 'mitoatlas') {

      padding = { top: 100, right: 180, bottom: 50, left: 70 };

    } else {

      padding = { top: 40, right: 180, bottom: 50, left: 70 };

    }

    if(enzyme==='APEX2'){
      margin = { top: 180, right: 0, bottom: 50, left: 0 };
    } else if (enzyme==='BioID'){
      margin = { top: 240, right: 0, bottom: 50, left: 0 };
    } else if (enzyme==='TurboID'){
      margin = { top: 280, right: 0, bottom: 50, left: 0 };
    } else {
      margin = { top: 180, right: 0, bottom: 50, left: 0 };
    }
    const labelTextSize='20px';
    const labelTextFontFamily='Arial';
    const width = 800;
    const height = 40*yKeys.length;
    // Append SVG element to container
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    svg.attr('white-space','pre')
      .attr('width', width+margin.left+margin.right+padding.left+padding.right)
      .attr('height', height + margin.top + margin.bottom + padding.top + padding.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left+padding.left}, ${margin.top+padding.top})`);

    svg.append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width+margin.left+margin.right+padding.left+padding.right)
      .attr("height", height+margin.top+margin.bottom+padding.top+padding.bottom)
      .attr("fill", "white");

    svg.append("rect")
      .attr("x", margin.left+padding.left)
      .attr("y", margin.top+padding.top)
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "lightgrey");

    // Create scales for the x-axis and y-axis
    const xScale = d3.scaleBand()
    .domain(xKeys)
    .range([margin.left+padding.left, width + margin.left+padding.left])
    .padding(0.00);

    const yScale = d3.scaleBand()
    .domain(yKeys)
    .range([margin.top+padding.top, height + margin.top+padding.top])
    .padding(0.00);

  let previous=margin.left+padding.left;
  const groupScales = {};

  groupKeys.forEach(group => {
    const groupsize = sortedGroups.filter(d => d.group === group).length;
    groupScales[group] = d3.scaleBand()
      .domain([group])
      .range([previous,previous + (width) / sortedGroups.length*groupsize])
      .padding(0.1);
      previous = previous + (width) / sortedGroups.length*groupsize;
  });
    // Create a color scale for the heatmap
    const colorScale = d3.scaleSequential()
    .interpolator(d3.interpolateReds)
    .domain([5,10]);

    const probColorScale = d3.scaleSequential()
    .interpolator(d3.interpolateBlues)
    .domain([0,1]);
    
    const experimentMap = sortedGroups.reduce((accumulator, currentValue) => {
      accumulator[currentValue.experiment] = currentValue.group;
      return accumulator;
    }, {});
    const tooltip = containerRef.current.querySelector('.tooltip');
    // Add the heatmap cells to the SVG

//add group data to result1

const dataWithGroup = data.result1[0].map(d => ({
  ...d,
  group: sortedGroups.find(e => e.experiment === d.experiment)?.group
}));

console.log('dataWithGroup');
console.log(dataWithGroup);

if (study === 'mitoatlas'){

  const probs = ['P_matrix', 'P_IMS', 'P_OMM', 'P_non-mito'];
  //draw heatmap cells for probability data
  probs.forEach(prob => {
  
    svg.selectAll('.cell')
    .data(sites)
    .join('g')
    .selectAll('.cell-rect')
    .data(d => [d]) // Wrap the data in an array to bind it to the join
    .join('rect')
    .attr('class', 'cell-rect')
    .attr("rx", 0).attr("ry", 0)
    .attr('x', d => xScale(prob + '_1'))
    .attr('y', d => yScale(d.site_id))
    .attr('width', xScale.bandwidth()*3)
    .attr('height', yScale.bandwidth())
    .attr('fill', d => {
      return probColorScale(d[prob]);
    })
    .on('mouseenter',(event,d)=>{
      const cellData = event.target.__data__;
      tooltip.style.display = 'block';
      let cellSite;
      if(enzyme === 'APEX2'){
        cellSite="Y"+cellData.site_id.split("_")[1];
      } else if(enzyme === 'BioID'||enzyme === 'TurboID'){
        cellSite="K"+cellData.site_id.split("_")[1];
      }
      const tooltipContent = `Site: ${cellSite}
        <br>${prob}: ${Math.round(cellData[prob]*10000)/10000}`;
      tooltip.innerHTML = tooltipContent;
    
      tooltip.style.left = `${event.clientX+10}px`;
      tooltip.style.top = `${event.clientY}px`;
    
      svg.append('rect')
        .attr('class', 'cell-highlight')
        .attr('x', xScale(prob + '_1'))
        .attr('y', yScale(d.site_id))
        .attr('width', xScale.bandwidth()*3)
        .attr('height', yScale.bandwidth())
        .attr('stroke', 'black')
        .attr('stroke-width', 2)
        .attr('fill', 'none')
        .raise();
    
    var tooltipNode = d3.selectAll(".tooltip");
    // Get the bounding rectangle of the tooltip element
    var tooltipRect = tooltipNode.node().getBoundingClientRect();
    
    // Check if the tooltip is rendered outside of the viewport
    if (tooltipRect.right > window.innerWidth) {
      // If the tooltip is outside of the viewport, adjust the position of the parent element
      var tooltipParent = tooltipNode.node().parentNode;
      tooltipParent.style.left = (parseFloat(tooltipParent.style.left) - (tooltipRect.right - window.innerWidth)) + "px";
    }
    })
    .on('mouseout', event => {
      svg.select('.cell-highlight').remove();
      tooltip.style.display = 'none';
    });
  
  });

}

//draw heatmap cells for intensity data
svg.selectAll('.cell')
.data(dataWithGroup)
.join('g')
.selectAll('.cell-rect')
.data(d => [d]) // Wrap the data in an array to bind it to the join
.join('rect')
.attr('class', 'cell-rect')
.attr("rx", 0).attr("ry", 0)
.attr('x', d => xScale(d.experiment))
.attr('y', d => yScale(d.site_id))
.attr('width', xScale.bandwidth())
.attr('height', yScale.bandwidth())
.attr('stroke', '#FFFFFF')
.attr('stroke-width', '1')
.attr('fill', d => {
  if (d.intensity === 0) {
    return 'lightgrey';
  } else {
    return colorScale(Math.log10(d.intensity));
  }
})
.on('mouseenter',(event,d)=>{
  const cellData = event.target.__data__;
  tooltip.style.display = 'block';
  const intensity = cellData.intensity.toExponential();
  let cellSite;
  if(enzyme === 'APEX2'){
    cellSite="Y"+cellData.site_id.split("_")[1];
  } else if(enzyme === 'BioID'||enzyme === 'TurboID'){
    cellSite="K"+cellData.site_id.split("_")[1];
  }
  const tooltipContent = `Site: ${cellSite}
    <br>Intensity: ${intensity}
    <br>Group: ${cellData.group}
    <br>Experiment: ${cellData.experiment}`;
  tooltip.innerHTML = tooltipContent;

  tooltip.style.left = `${event.clientX+10}px`;
  tooltip.style.top = `${event.clientY}px`;

  svg.append('rect')
    .attr('class', 'cell-highlight')
    .attr('x', xScale(d.experiment))
    .attr('y', yScale(d.site_id))
    .attr('width', xScale.bandwidth())
    .attr('height', yScale.bandwidth())
    .attr('stroke', 'black')
    .attr('stroke-width', 2)
    .attr('fill', 'none')
    .raise();

var tooltipNode = d3.selectAll(".tooltip");
// Get the bounding rectangle of the tooltip element
var tooltipRect = tooltipNode.node().getBoundingClientRect();

// Check if the tooltip is rendered outside of the viewport
if (tooltipRect.right > window.innerWidth) {
  // If the tooltip is outside of the viewport, adjust the position of the parent element
  var tooltipParent = tooltipNode.node().parentNode;
  tooltipParent.style.left = (parseFloat(tooltipParent.style.left) - (tooltipRect.right - window.innerWidth)) + "px";
}
})
.on('mouseout', event => {
  svg.select('.cell-highlight').remove();
  tooltip.style.display = 'none';
});

// Add y axis to SVG
let yaxisdraw = svg.append('g')
  .call(d3.axisRight(yScale).tickSize(width))
  .attr('transform', `translate(${margin.left+padding.left}, 0)`);

let yaxisText = yaxisdraw.selectAll("text").text((d)=>d);

let yaxisdraw2 = svg.append('g')
  .call(d3.axisLeft(yScale)
  .tickSize(50))
  .attr('transform', `translate(${margin.left+padding.left}, 0)`);

yaxisdraw2.selectAll("text").remove();

yaxisText.attr('dx', -width-5 )
  .attr("text-anchor",'end');

yaxisdraw.selectAll("line")
  .attr('transform', `translate(0, ${yScale.bandwidth()/2})`);

yaxisdraw2.selectAll("line")
  .attr('transform', `translate(0, ${yScale.bandwidth()/2})`);

yaxisdraw.selectAll(".tick:last-child line")
  //.remove();

yaxisdraw.select(".domain")
  .attr("stroke-width",1);

yaxisText.each(function() {
  // Get the text content and length
  const text = d3.select(this);
  
  const parent = text.node().parentNode;
  const textContent =  text.text();
  

  // Find the matching object in the array
  const match = sites.find(d => d.site_id === textContent);

  if(enzyme === 'APEX2'){
    text.attr('font-size',labelTextSize)
    .attr('font-family',labelTextFontFamily)
    .text((d) => "Y" + d.split("_")[1]);
  } else if(enzyme === 'BioID'|| enzyme ==='TurboID'){
    text.attr('font-size',labelTextSize)
    .attr('font-family',labelTextFontFamily)
    .text((d) => "K" + d.split("_")[1])
  }
  // Get the siteloc attribute of the matching object
  let siteloc = '';
  if (study === 'mitoatlas'){
    siteloc = match ? match.Localization_mitoatlas : null;
  } else if (study==='sarscov2'){
    siteloc = match ? match.sarscov2_interactome : null;
  }

  const bbox = text.node().getBBox();
  const textLength = text.node().getComputedTextLength();
  d3.select(parent).insert("rect", ":first-child")
      .attr("x", bbox.x)
      .attr("y", bbox.y)
      .attr("width", textLength)
      .attr("height", bbox.height)
      .attr("class",`${siteloc}`)
      .style("fill", style.find(obj=>obj.hasOwnProperty('category')&&obj.category===siteloc).lighthex);
})

// Draw the group axes
groupKeys.forEach(group => {

  let tempscale=d3.axisTop(groupScales[group])
    .tickSize(margin.top/1.414-20)
    .tickSizeInner(0);

  let tempscale2=d3.axisBottom(groupScales[group])
    .tickSize(height)
    .tickSizeInner(0);

  let axisGroup=svg.append("g")
    .call(tempscale)
    .attr('transform', `translate(0, ${margin.top +padding.top})`);

  let axisGroup2 = svg.append("g")
    .call(tempscale2)
    .attr('transform', `translate(0, ${margin.top +padding.top})`);

  axisGroup2.selectAll("text").remove();

  const axisGroupText = axisGroup.selectAll("text")
    .style("text-anchor", "start")
    .attr('dx', '0.5em')
    .attr("dy", "0.5em")
    .attr('font-size',labelTextSize)
    .attr('font-family',labelTextFontFamily)
    .attr("transform", "rotate(-45 0 0)");
  
  // Insert a <rect> element before each text element
  axisGroupText.each(function() {
    const text = d3.select(this);
    
    //check if 'P_' is in part of the text and if true, skip
    if (text.text().includes('P_')) return;

    const bbox = text.node().getBBox();
    const parent = text.node().parentNode;

    // Get the text content and length
    const textContent = text.text();
    const textLength = text.node().getComputedTextLength();
    const enzymeIndex = textContent.indexOf(`${enzyme}`);
    const enzymeLength = `${enzyme}`.length;
    const enzymePosition = text.node().getStartPositionOfChar(enzymeIndex);
    const enzymeWidth = text.node().getSubStringLength(enzymeIndex, enzymeLength);

    // Find the matching object in the array
    const match = sortedGroups.find(d => d.group === textContent);

    // Get the baitloc attribute of the matching object
    const baitloc = match ? match.baitloc : null;
    
    d3.select(parent).insert("rect", ":first-child")
      .attr("transform", "rotate(-45)")
      .attr("x", bbox.x+enzymePosition.x-10)
      .attr("y", bbox.y+1)
      .attr("width", enzymeWidth)
      .attr("height", bbox.height-2)
      .style("fill", style.find(obj=>obj.hasOwnProperty('category')&&obj.category===enzyme).lighthex);

      d3.select(parent).insert("rect", ":first-child")
      .attr("transform", "rotate(-45 0 0)")
      .attr("x", bbox.x)
      .attr("y", bbox.y+1)
      .attr("width", textLength)
      .attr("height", bbox.height-2)
      .style("fill", style.find(obj=>obj.hasOwnProperty('category')&&obj.category===baitloc).lighthex);
  });

  axisGroup.select(".domain")
    .attr( "stroke-width" , 1 )
    .attr( "transform" , "skewX(-45)" )
    .raise();
});

// Add title to heatmap
const title = svg.append('g')
  .attr('x', 0)
  .attr('y', padding.top+20)
  .attr("transform", `translate(${margin.left+padding.left+width/2},${padding.top+10})`);

const titletext = title.append('text')
  .attr('text-anchor', 'middle')
  .attr('font-weight', 'bold')
  .attr('font-size','24px');

const enzymeText = titletext.append('tspan')
  .attr('class', `${enzyme}`)
  .text(`${enzyme}`);

titletext.append('tspan')
  .text(` Intensity Heatmap of ${genename}`);

const bbox =  enzymeText.node().getBBox();

// Create a rect element to surround the text
title.append("rect")
  .attr("x", bbox.x+2)
  .attr("y", bbox.y)
  .attr("width", bbox.width)
  .attr("height", bbox.height)
  .style("fill", style.find(obj=>obj.hasOwnProperty('category')&&obj.category===enzyme).lighthex);

  titletext.remove();

  const titletextNew = title.append('text')
  .attr('text-anchor', 'middle')
  .attr('font-weight', 'bold')
  .attr('font-size','24px');
  titletextNew.append('tspan')
    .attr('class', `${enzyme}`)
    .text(`${enzyme} `);
  titletextNew.append('tspan')
    .text(`Intensity Heatmap of ${genename}`);

  //add intensity colorscale legend
  const legendWidth = 240;
  const legendHeight = 20;
  const legendPadding = 10;

  const legend = svg.append("g")
    .attr("class", "legend")
    .attr("transform", `translate(${margin.left+width-legendWidth/2+29},${padding.top+10})`);

    const legendScale = d3.scaleLinear()
    .domain([5, 10])
    .range([0, legendWidth - 2 * legendPadding]);

  const legendAxis = d3.axisBottom(colorScale)
    .tickSize(0);

  legend.append("g")
    .attr("class", "legend-axis")
    .call(legendAxis)
    .attr("transform", `translate(${legendPadding},${legendPadding+legendHeight})`)
    .select(".domain")
    .remove();
  
  legend.selectAll("text")
    .remove();

  legend.selectAll(".legend-rect")
  .data(d3.range(5, 10, 0.05))
  .enter()
  .append("rect")
  .attr("class", "legend-rect")
  .attr("x", function(d) { return legendScale(d) + legendPadding; })
  .attr("y", legendPadding)
  .attr("width", legendScale(0.06) - legendScale(0))
  .attr("height", legendHeight)
  .attr("fill", function(d) { return colorScale(d); });

  legend.selectAll(".legend-label")
    .data(d3.range(5, 11, 1))
    .enter()
    .append("text")
    .attr("class", "legend-label")
    .attr("x", function(d) { return legendScale(d) + legendPadding; })
    .attr("y", legendHeight + legendPadding)
    .attr("dy", "1em")
    .attr("text-anchor", "middle")
    .attr("font-size", "16px")
    .attr("font-family", "Arial, sans-serif")
    .text(function(d) { return d3.format(".0e")(Math.pow(10, d)); });

  legend.selectAll(".legend-tick")
    .data(d3.range(5,11,1))
    .enter()
    .append("line")
    .attr("class", "legend-tick")
    .style("stroke-width",1)
    .style("stroke","black")
    .attr("x1", function(d) { return legendScale(d) + legendPadding; })
    .attr("y1", legendHeight + legendPadding)
    .attr("x2", function(d) { return legendScale(d) + legendPadding; })
    .attr("y2", legendHeight + legendPadding+2);

  // Add title to legend
  legend.append('text')
    .attr('x', legendWidth/2)
    .attr('y', 0)
    .attr('text-anchor', 'middle')
    .text('Intensity colorscale');

  if (study === "mitoatlas") {
    //add probability colorscale legend
    const legendWidth2 = 240;
    const legendHeight2 = 20;
    const legendPadding2 = 10;
    
    const legend2 = svg.append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${margin.left+width-legendWidth2/2+29},${padding.top-70})`);

    const legendScale2 = d3.scaleLinear()
      .domain([0, 1])
      .range([0, legendWidth2 - 2 * legendPadding2]);
    
    const legendAxis2 = d3.axisBottom(probColorScale)
      .tickSize(0);

    legend2.append("g")
      .attr("class", "legend-axis")
      .call(legendAxis2)
      .attr("transform", `translate(${legendPadding2},${legendPadding2+legendHeight2})`)
      .select(".domain")
      .remove();
    
    legend2.selectAll("text")
      .remove();
    
    legend2.selectAll(".legend-rect")
      .data(d3.range(0, 1, 0.01))
      .enter()
      .append("rect")
      .attr("class", "legend-rect")
      .attr("x", function(d) { return legendScale2(d) + legendPadding2; })
      .attr("y", legendPadding2)
      .attr("width", legendScale2(0.012) - legendScale2(0))
      .attr("height", legendHeight2)
      .attr("fill", function(d) { return probColorScale(d); });
    
    legend2.selectAll(".legend-label")
      .data(d3.range(0, 1.1, 0.2))
      .enter()
      .append("text")
      .attr("class", "legend-label")
      .attr("x", function(d) { return legendScale2(d) + legendPadding2; })
      .attr("y", legendHeight2 + legendPadding2)
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-family", "Arial, sans-serif")
      .text(function(d) { return d3.format(".0%")(d); });
    
    legend2.selectAll(".legend-tick")
      .data(d3.range(0,1.1,0.2))
      .enter()
      .append("line")
      .attr("class", "legend-tick")
      .style("stroke-width",1)
      .style("stroke","black")
      .attr("x1", function(d) { return legendScale2(d) + legendPadding2; })
      .attr("y1", legendHeight2 + legendPadding2)
      .attr("x2", function(d) { return legendScale2(d) + legendPadding2; })
      .attr("y2", legendHeight2 + legendPadding2+2);
    
    // Add title to legend
    legend2.append('text')
      .attr('x', legendWidth2/2)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .text('Probability colorscale');

  }

  //select all fonts and arial
  svg.selectAll("text")
    .style("font-family", "Arial");
    tooltip.style.fontFamily = "Arial";
    tooltip.style.fontSize = "20px";

      const filename=`${study}_heatmap_${enzyme}_${genename}`

      const saveOptions = [
        { format: "svg", label: "Save as SVG" },
        { format: "jpeg", label: "Save as JPEG" },
        { format: "png", label: "Save as PNG" },
        { format: "pdf", label: "Save as PDF" }
      ];
  
      saveButton(btncontainerRef, saveOptions, svgRef, 
        width+margin.left+margin.right+padding.left+padding.right,
        height+margin.top+margin.bottom+padding.top+padding.bottom,
         filename);

  
    }, [data, loading, enzyme, genename, isdata, style]);
    //display loading text when data is loading;
    if (loading) {
    return <div>Loading...</div>;
    } else if (!isdata) {
      return <div>Sorry! this protein was not detected using {enzyme}.</div>;
    }
    
    return (<>
    <div className="chart-container heatmap" ref={containerRef}>
      <div className='savebtn_container' ref={btncontainerRef}>
      </div>
      <svg className='heatmap_svg' ref={svgRef}></svg>
      <div className="tooltip"></div>
    </div>
    </>
    );
    }
    export default Heatmap;