/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './SarsCov2General.css';
import { useLocation, Link } from 'react-router-dom';

function SarsCov2General({style, genename, proteinname, uniprotID , localization_mitoatlas , localization_uniprot}){
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isdata, setIsdata] = useState(false)
    const source = axios.CancelToken.source();

    const bgcolorTurboID = style.find(obj=>obj.hasOwnProperty('category')&&obj.category==='TurboID').lighthex;
    const bgcolorORF3a = style.find(obj=>obj.hasOwnProperty('category')&&obj.category==='SARS-CoV-2_ORF3a').lighthex;
    const bgcolorM = style.find(obj=>obj.hasOwnProperty('category')&&obj.category==='SARS-CoV-2_M').lighthex;
    const bgcolorORF3aANDM = style.find(obj=>obj.hasOwnProperty('category')&&obj.category==='SARS-CoV-2_ORF3a_AND_M').lighthex;

    useEffect(() => {
        const study='sarscov2';
        setLoading(true);
        console.log(`getting ${study} data of ${uniprotID}`);
        Promise.all([
          axios.get(`https://spatiomics.org/api/pldata?study=${study}&uid=${uniprotID}&sitefeature=sarscov2_interactome&enzyme=${'TurboID'.toLowerCase()}`)
        ])
          .then(([response]) => {
            console.log(`got ${study} data of ${uniprotID}`);
            //sort sites by site number
            let parsedSites = response.data.sort((a, b) => {
              const siteIdA = parseInt(a.site_id.split('_')[1]);
              const siteIdB = parseInt(b.site_id.split('_')[1]);
            
              return siteIdA - siteIdB;
            });

            parsedSites.forEach(obj=>{
              obj.enzyme='TurboID';
              obj.site=parseInt(obj.site_id.split('_')[1]);
            })


            setSites(parsedSites);
            console.log(response.data);
            if(response.data.length>0){
              setIsdata(true);
            }
          })
          .catch(error => {
            console.error(`there was an error communicating to the API: ${error}`);
          })
          .finally(() => {
            setLoading(false);
          });
        return () => {
          source.cancel();
        };
      }, [uniprotID]);

    
    
    if (loading) {
    return <div>Loading...</div>;
    }
    if (!isdata) {
        return <div>Sorry! no data!</div>;
    }

    return    <table class="general-properties-table">
    <tbody class="tbody">
      <tr class="row">
        <td class="column"><strong>Organism:</strong></td>
        <td class="column"><em>Homo sapiens</em></td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Protein name, UniProt:</strong></td>
        <td class="column">{proteinname}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Gene name, UniProt:</strong></td>
        <td class="column">{genename}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>UniProt ID:</strong></td>
        <td class="column"><a href={`https://www.uniprot.org/uniprotkb/${uniprotID}/entry`}
        target="_blank" rel="noreferrer" >{uniprotID}</a></td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Localization, MitoAtlas:</strong></td>
        <td class="column">{localization_mitoatlas}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Localization, Uniprot:</strong></td>
        <td class="column">{localization_uniprot}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong># of total <span style={{ backgroundColor: bgcolorTurboID
                        }}>TurboID</span> labeled sites:</strong></td>
        <td class="column">{sites.length}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong># of <span style={{ backgroundColor: bgcolorORF3a
                        }}>SARS-CoV-2 ORF3a</span> specific sites:</strong></td>
        <td class="column">{sites.filter(d => (d.sarscov2_interactome === 'SARS-CoV-2_ORF3a'|| d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M')).length}</td>
      </tr>
      {sites.filter(d => (d.sarscov2_interactome === 'SARS-CoV-2_ORF3a'|| d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M')).length>0 &&
      <tr class="row">
        <td class="column"><strong>positions of <span style={{ backgroundColor: bgcolorORF3a
                        }}>ORF3a</span> specific sites:</strong></td>
        <td class="column">
        {
            sites.filter(d => (d.sarscov2_interactome === 'SARS-CoV-2_ORF3a'|| d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M')).map((d,i)=>{
                return (<span>
                        {d.site && (d.sarscov2_interactome==='SARS-CoV-2_ORF3a') &&
                        <span style={{ backgroundColor: bgcolorORF3a
                        }}>
                        K{d.site}
                        </span>
                        }{d.site && (d.sarscov2_interactome==='SARS-CoV-2_ORF3a_AND_M') &&
                        <span style={{ backgroundColor: bgcolorORF3aANDM
                        }}>
                        K{d.site}
                        </span>
                        }
                        {i < sites.filter(d => (d.sarscov2_interactome === 'SARS-CoV-2_ORF3a'|| d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M')).length - 1 && ", "}
                </span>
                )
            })
        }
        </td>
      </tr>}

      <tr class="row">
        <td class="column"><strong># of <span style={{ backgroundColor: bgcolorM
                        }}>SARS-CoV-2 M</span> specific sites:</strong></td>
        <td class="column">{sites.filter(d => ( d.sarscov2_interactome === 'SARS-CoV-2_M' || d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M' )).length}</td>
      </tr>
      {sites.filter(d => ( d.sarscov2_interactome === 'SARS-CoV-2_M' || d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M' )).length>0 &&
      <tr class="row">
        <td class="column"><strong>positions of <span style={{ backgroundColor: bgcolorM
                        }}>M</span> specific sites:</strong></td>
        <td class="column">
        {
            sites.filter(d => (d.sarscov2_interactome === 'SARS-CoV-2_M'|| d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M')).map((d,i)=>{
                return (<span>
                        {d.site && d.sarscov2_interactome==='SARS-CoV-2_M' &&
                        <span style={{ backgroundColor: bgcolorM
                        }}>
                        K{d.site}
                        </span>
                        }
                        {d.site && (d.sarscov2_interactome==='SARS-CoV-2_ORF3a_AND_M') &&
                        <span style={{ backgroundColor: bgcolorORF3aANDM
                        }}>
                        K{d.site}
                        </span>
                        }
                        {i < sites.filter(d => (d.sarscov2_interactome === 'SARS-CoV-2_M'|| d.sarscov2_interactome === 'SARS-CoV-2_ORF3a_AND_M')).length - 1 && ", "}
                </span>
                )
            })
        }
        </td>
      </tr>}

      <tr class="row">
        <td class="column"><strong>Specific sites:</strong></td>
        <td class="column" 
        style={{whiteSpace: "pre-line"}}>
            {sites.map(d => {
      let bgcolorEnzyme = style.find(obj=>obj.hasOwnProperty('category')&&obj.category==='TurboID').lighthex;
      let bgcolorLocalization = style.find(obj=>obj.hasOwnProperty('category')&&obj.category===d.sarscov2_interactome).lighthex;
      let site ='K'+d.site;
      let localization = d.sarscov2_interactome;

      return (
        <span>
            <span style={{ backgroundColor: bgcolorEnzyme 
            }}>
              {site}
            </span>
            : 
            <span style={{ backgroundColor: bgcolorLocalization 
            }}>
                {localization}
            </span>
          <br/>
        </span>
      );
    })}
        </td>
      </tr>
    </tbody>
  </table>
}

export default SarsCov2General;