import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import saveButton from './saveButton';

const MoimPAE = ({ fileName, geneNameA, geneNameB, lengthA, lengthB}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [matrixData, setMatrixData] = useState([]);

  const d3Container = useRef(null);

  const btncontainerRef=useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://spatiomics.org/pdb/${fileName}_best_pae.json`);
        const jsonData = await response.json();
        setIsLoading(false);
        setMatrixData(jsonData[0].predicted_aligned_error);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [fileName]);

  useEffect(() => {
    if (matrixData && d3Container.current) {
    
      const svg = d3.select(d3Container.current);

      // Clear SVG to prevent duplication
      svg.selectAll("*").remove();

      // Set up dimensions
      const cellSize = 1;
      const margin = { top: 80, right: 20, bottom: 100, left: 150 };
      //const width = cellSize * (matrixData[0]?.length || 0) + margin.left + margin.right;
      //const height = cellSize * matrixData.length + margin.top + margin.bottom;
      const width = 500;
      const height = 500;

      // Append SVG to the container
      svg.attr("width", width + margin.left + margin.right)
         .attr("height", height + margin.top + margin.bottom);

    // Define the range of your data values (min and max)
    const minValue = 0;
    const maxValue = 32; // Change this to the maximum value in your data

    // Create a linear color scale with 11 stops, mapping the range of your data to the colors
    const colorScale = d3.scaleLinear()
    .domain(d3.range(minValue, maxValue + 1, (maxValue - minValue) / 10))
    .range([
        "rgb(27, 66, 35)",
        "rgb(40, 89, 50)",
        "rgb(55, 111, 66)",
        "rgb(72, 132, 84)",
        "rgb(90, 151, 102)",
        "rgb(110, 170, 122)",
        "rgb(132, 187, 143)",
        "rgb(155, 204, 165)",
        "rgb(180, 219, 188)",
        "rgb(207, 234, 212)",
        "rgb(235, 247, 237)"
    ]);

    // Create a canvas element to draw the image
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = lengthA + lengthB;
    canvas.height = lengthA + lengthB;

    // Draw the image data on the canvas
    matrixData.forEach((row, i) => {
      row.forEach((value, j) => {
        context.fillStyle = colorScale(value);
        context.fillRect(j, i, 1, 1); // Draw pixel by pixel
      });
    });

    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL();

    const pattern = svg.append("defs")
      .append("pattern")
      .attr("id", "data-pattern")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", width)
      .attr("height", height);

    pattern.append("image")
      .attr("xlink:href", dataURL)
      .attr("width", width)
      .attr("height", height);

    // Translate the main group to leave space for the margins
    const mainGroup = svg.append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    // Append a large rect to use the pattern
    mainGroup.append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "url(#data-pattern)")
      .attr("stroke", "black");

    // Create X and Y axis scales
    const xScale = d3.scaleLinear()
        .range([margin.left, width + margin.left])
        .domain([1, matrixData[0].length]);

    const yScale = d3.scaleLinear()
        .range([margin.top, height + margin.top])
        .domain([1, matrixData.length]); 

    const xScaleA = d3.scaleLinear()
        .range([xScale(1), xScale(lengthA)])
        .domain([1, lengthA]);
    
    const xScaleB = d3.scaleLinear()
        .range([xScale(lengthA + 1), xScale(lengthA + lengthB)])
        .domain([1, lengthB]);

    const yScaleA = d3.scaleLinear()
        .range([yScale(1), yScale(lengthA)])
        .domain([1, lengthA]);
    
    const yScaleB = d3.scaleLinear()
        .range([yScale(lengthA + 1), yScale(lengthA + lengthB)])
        .domain([1, lengthB]);

    // Add X axis to the SVG
    svg.append("g")
        .attr("transform", "translate(0," + (margin.top) + ")")
        .call(d3.axisTop(xScaleA).ticks(1 + 10*lengthA/(lengthA + lengthB)));
    
    svg.append("g")
        .attr("transform", "translate(0," + (margin.top) + ")")
        .call(d3.axisTop(xScaleB).ticks(1 + 10*lengthB/(lengthA + lengthB)));    

    // Add Y axis to the SVG
    svg.append("g")
        .attr("transform", "translate(" + margin.left + ", 0)")
        .call(d3.axisLeft(yScaleA).ticks(1 + 10*lengthA/(lengthA + lengthB)));

    svg.append("g")
        .attr("transform", "translate(" + margin.left + ", 0)")
        .call(d3.axisLeft(yScaleB).ticks(1 + 10*lengthB/(lengthA + lengthB)));

    // Define a linear gradient for the color scale (this could be vertical or horizontal)
    const defs = svg.append("defs");
    const linearGradient = defs.append("linearGradient")
        .attr("id", "linear-gradient");

    colorScale.range().forEach((color, i, arr) => {
    linearGradient.append("stop")
        .attr("offset", (i / arr.length))
        .attr("stop-color", color);
    });

    // Draw the color scale bar
    const legendWidth = 200; // Width of the legend bar
    const legendHeight = 20; // Height of the legend bar

    svg.append("rect")
        .attr("width", legendWidth)
        .attr("height", legendHeight)
        .style("fill", "url(#linear-gradient)")
        .attr("stroke", "black")
        .attr("transform", "translate(" + (width/2 + margin.left - legendWidth/2) + "," + (height + margin.top + 40) + ")");

    // Add color scale labels
    const legendScale = d3.scaleLinear()
    .domain([0, 31.75])
    .range([0, legendWidth]);

    svg.append("g")
        .attr("class", "legend-axis")
        .attr("transform", "translate(" + (width/2 + margin.left - legendWidth/2) + "," + (height + margin.top + 40 + legendHeight) + ")")
        .call(d3.axisBottom(legendScale));

    svg.append("text")
        .attr("x", width/2 + margin.left)
        .attr("y", height + margin.top + 25)
        .attr("text-anchor", "middle") // Center the text around the x coordinate
        .text("Predicted Aligned Error (Å)"); // Your title text

    svg.append("text")
        .attr("x", xScale((lengthA + lengthB)/2))
        .attr("y", yScale(0) - 60)
        .attr("text-anchor", "middle") // Center the text around the x coordinate
        .text("Scored residue"); // Your title text

    svg.append("text")
        .attr("text-anchor", "middle") // Center the text around the x coordinate
        .attr("transform", "translate(" + (xScale(0) - 70) + "," + yScale((lengthA + lengthB)/2) + ") rotate(-90) ")
        .text("Aligned residue"); // Your title text

    svg.append("text")
        .attr("x", xScale((lengthA + 1)/2))
        .attr("y", yScale(0) - 30)
        .attr("text-anchor", "middle") // Center the text around the x coordinate
        .text(geneNameA); // Your title text
    
    svg.append("text")
        .attr("text-anchor", "middle") // Center the text around the x coordinate
        .attr("transform", "translate(" + (xScale(0) - 40) + "," + yScale((lengthA + 1)/2) + ") rotate(-90) ")
        .text(geneNameA); // Your title text
    
    svg.append("text")
        .attr("x", xScale(lengthA + (lengthB + 1)/2))
        .attr("y", yScale(0) - 30)
        .attr("text-anchor", "middle") // Center the text around the x coordinate
        .text(geneNameB); // Your title text
    
    svg.append("text")
        .attr("transform", "translate(" + (xScale(0) - 40) + "," + yScale(lengthA + (lengthB + 1)/2) + ") rotate(-90) ")
        .attr("text-anchor", "middle") // Center the text around the x coordinate
        .text(geneNameB); // Your title text

    // Append a vertical line at x = lengthA
    svg.append("line")
        .attr("x1", xScale(lengthA + 0.5))
        .attr("y1", yScale(1))
        .attr("x2", xScale(lengthA  + 0.5))
        .attr("y2", yScale(lengthA + lengthB))
        .attr("class", "protein-line")
        .style("stroke", "black") // Choose a color that makes the line distinct
        .style("stroke-width", 1);

    // Append a horizontal line at y = lengthA
    svg.append("line")
        .attr("x1", xScale(1))
        .attr("y1", yScale(lengthA + 0.5))
        .attr("x2", xScale(lengthA + lengthB))
        .attr("y2", yScale(lengthA + 0.5))
        .attr("class", "protein-line")
        .style("stroke", "black") // Choose a color that makes the line distinct
        .style("stroke-width", 1);
        
    const filename=`PAE_of_${geneNameA}-${geneNameB}`

    const saveOptions = [

    { format: "svg", label: "Save as SVG" },
    { format: "jpeg", label: "Save as JPEG" },
    { format: "png", label: "Save as PNG" },
    { format: "pdf", label: "Save as PDF" }

    ];

    const calcWidth = width + margin.left + margin.right;
    
    const calcHeight = height + margin.top + margin.bottom;

    if(d3Container.current){

        saveButton(btncontainerRef, saveOptions, d3Container,
            calcWidth,
            calcHeight,
            filename);

    }

}
  }, [matrixData, geneNameA, geneNameB, lengthA, lengthB]);

  if (isLoading) {
    return (
        <div>Loading...</div>
    );
  }
  return (
    <>
    <svg ref={d3Container}></svg>
    <div className='savebtn_container' ref={btncontainerRef}
          style={{
              position:"relative",
          }}>
        </div>
    </>
    
  );
};

export default MoimPAE;
