import axios from 'axios';
import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export default function useAutoSearch(query) {

  const [results, setResults] = useState([]);

  const [loading, setLoading] = useState(false);

  const currentHost = window.location.hostname;

  let subDomain = '';

  if (currentHost.split('.').length >= 3 ) {

    subDomain = currentHost.split('.')[0];

  }

  useEffect(() => {
    // create a new cancellation token for each request
    const source = axios.CancelToken.source();

    if (query.length<3) {

      setResults([]);

    } else if (query !== '') {

      setLoading(true);

      const debouncedQuery = debounce(() => {

      let getQuery = `https://spatiomics.org/api/search?search=${query}&auto=true`
      //filter sarscov2 proteins if subdomain is sarscov2
      if( subDomain === 'sarscov2' ){

        getQuery = `https://spatiomics.org/api/search?search=${query}&filter=sarscov2&auto=true`

      } else if(subDomain == 'moim'){

        getQuery = `https://spatiomics.org/api/search?search=${query}&filter=moim&auto=true`

      }

        axios.get(getQuery, {

            cancelToken: source.token

        })
            .then(response => {

            setResults(response.data);

            setLoading(false);

            })

            .catch(error => {

            if (!axios.isCancel(error)) {

                console.error(error);

            }

            setLoading(false);

            });

        }, 100);

        debouncedQuery();

    } else {

        setResults([]);

      }
    // cancel the previous request when a new query is entered
    return () => source.cancel();

  }, [query]);

  return [results, loading];

}
