export default function parseTM(db,tmstring){
    if (db === 'Manual') {
        const regex = /(\d+)_(\d+),?\s*/g;
    
        let match, parsedtm = [];
    
        while ((match = regex.exec(tmstring)) !== null) {
            const [_, start, end] = match;
            parsedtm.push({ start: Number(start), end: Number(end) });
        }
    
        return parsedtm;
    } else if(db==='UniProt'){
        const regex = /TRANSMEM (\d+)\.\.(\d+); \/note="(\w+)".*?/g;

        let match, parsedtm = [];
      
        while ((match = regex.exec(tmstring)) !== null) {
          const [_, start, end, type] = match;
          parsedtm.push({ type,start: Number(start), end: Number(end)});
        }

        return parsedtm

    } else if(db==='TMbed'){
        const regex = /([\w-]+),(\d+)-(\d+);/g;
        let match, parsedtm = [];
        while ((match = regex.exec(tmstring)) !== null) {
            const [_, type, start, end] = match;
            if(type!=='non_transmembrane'){
                    parsedtm.push({ type, start: Number(start), end: Number(end) });
            }   
        }
        return parsedtm;
    } else if(db==='DeepTMHMM'){
        const regex = /(\w+):(\d+)-(\d+);/g;
        let match, parsedtm = [];

        while ((match = regex.exec(tmstring)) !== null) {
            const [_, type, start, end] = match;
            if(type!=='inside'&& type!=='outside'){
            parsedtm.push({ type, start: Number(start), end: Number(end) });
            }
  }

  return parsedtm;

    }
    return 0;
}